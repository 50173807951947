import React, { useMemo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'
import SortableTableCells from 'components/SortableTableCells'

const SegmentTargetingTable = props => {
  const { params, data, page, onPageChange, onSortChange, options } = props

  const label = useMemo(() =>
    options.reduce((res, { label, value }) => ({ ...res, [value]: label }), {})
  , [options])

  const sortableCols = useMemo(() => ([
    { name: 'content_id', children: 'Content ID' },
    { name: 'content_name', children: 'Content Name' },
  ]), [])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCells
                    cells={sortableCols}
                    onOrderByChange={onSortChange}
                    customOrderBy={{ column: params.column, order: params.order }}
                  />
                  <TableCell>Content Type</TableCell>
                  <TableCell>NS_F</TableCell>
                  <TableCell>SR_f</TableCell>
                  <TableCell>NS_M</TableCell>
                  <TableCell>SR_M</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.content_id}</TableCell>
                    <TableCell>{row.trainer?.name || row.workout_resource?.name}</TableCell>
                    <TableCell>{label[row.content_type]}</TableCell>
                    <TableCell>{row.ns_f}</TableCell>
                    <TableCell>{row.sr_f}</TableCell>
                    <TableCell>{row.ns_m}</TableCell>
                    <TableCell>{row.sr_m}</TableCell>
                    <TableCell>{ booleanElement(row.active) }</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/workout/quality-score-boosts/${row.id}/edit`} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onPageChange={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default SegmentTargetingTable