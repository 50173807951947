import React from 'react'
import { Grid, TextField } from '@material-ui/core'

export default function ProgramNutritionToolbar (props) {
  const { params, onParamsChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='Workout ID' size='small' className='background-color' value={params.workoutId}
            onChange={event => onParamsChange({ workoutId: event.target.value }, 500)} />
        </Grid>

        <Grid container item xs={8} justifyContent={'flex-end'}>
          {/* <Button color='primary' type='submit' style={{marginRight: 0}} variant='contained'
            onClick={e => {
              e.stopPropagation()
              generateWorkoutChapter(params.workoutId).then(() => window.location.reload())
            }}>
            Generate
          </Button> */}
        </Grid>
      </Grid>
    </div>
  )
}
