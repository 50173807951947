import { getThemeDetailPromise } from 'api/Theme'
import React, { useEffect, useState } from 'react'
import Form from './form'

export default function BlacklistAdd (props) {
  const { id, type } = props.match.params
  const [data, setData] = useState({ senior: true, non_senior: true, female: true, male: true, active: false })
  // const [itemOptions, setItemOptions] = useState()

  useEffect(() => {
    if (id) getThemeDetailPromise(id).then(resp => resp && setData(resp))
  }, [id])

  return <Form mode={id ? 'edit' : 'add'} data={data} setData={setData} type={type} {...props} />
}
