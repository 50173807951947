import Setup from './components/setup'
import Parking from './components/parking'
import Amenities from './components/amenity'
import { Button, Grid } from '@material-ui/core'
import { Overview } from './components/overview'
import EvCharging from './components/evCharging'
import { upsertFohStudioPromise } from 'api/studio'
import Accessibility from './components/accessibility'
import React, { useCallback, useLayoutEffect, useState } from 'react'

export default function UserGroupForm (props) {
  const { data, history } = props
  const [studio, setStudio] = useState(data)

  useLayoutEffect(() => {
    setStudio(data)
  }, [data])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertFohStudioPromise(studio)
      .then(() => props.history.push('/foh-studios'))
  }, [props.history, studio])

  const components = [Overview, Setup, Accessibility, Amenities, Parking, EvCharging]

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {studio.id ? 'Edit' : 'New'} FOH Studio
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {
            components.map((card, idx) => {
              return <div key={`div-${idx}`}>
                { card({ data, studio, setStudio, key: `card-${idx}` }) }
                <br />
              </div>
            })
          }
        </Grid>
      </Grid>
    </div>

  </div>
}
