import UpsertForm from './form'
import { getRecipeTagDetail } from 'api/RecipeTag'
import React, { useState, useLayoutEffect } from 'react'

export default function (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getRecipeTagDetail(id).then(setData)
  }, [id])

  return <UpsertForm { ...props } data={data} />
}
