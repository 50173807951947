import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'

const MealTable = props => {
  const { data, page, onPageChange, history } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Merchant Name</TableCell>
                  <TableCell>Studio ID</TableCell>
                  <TableCell>Studio Name</TableCell>
                  <TableCell>Verified</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows?.map(row => {
                  return <TableRow key={`${row.id}-${row['merchant_studios.id']}`} hover>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row['merchant_studios.studio_id']}</TableCell>
                    <TableCell>{row['merchant_studios.studio.name']}</TableCell>
                    <TableCell>{ booleanElement(row.verified) }</TableCell>
                    <TableCell>{ booleanElement(row.active) }</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} from={props.location} path={`/flex-network/merchants/${row.id}/edit`} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default MealTable