import _ from 'lodash'
import React from 'react'
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'

export default function Overview (props) {
  const { studio } = props

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>Accessibility</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      {
        _.map(studio.accessibility, (value, key) => <Grid item xs={6} key={key}>
          <Grid item xs={6} className='bottom-dashed'>
            <BooleanToggleButtonGroup
              label={_.startCase(key)} name={key} withoutNull={true} value={value} onChange={() => {}} />
          </Grid>
        </Grid>)
      }
    </Grid>
  </Card>
}