import React from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { formatDateTime } from 'common/usual'
import { booleanElement } from 'helpers/utils'

const PromoTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Foh Studio ID</TableCell>
                  <TableCell>Display Name</TableCell>
                  <TableCell>Edit Override</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Updated At Advanced</TableCell>
                  <TableCell>Updated At Preferred</TableCell>
                  <TableCell>Edit</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => (
                  <TableRow key={row._id} hover>
                    <TableCell>{row.foh_studio_id}</TableCell>
                    <TableCell>{row.display_name}</TableCell>
                    <TableCell>{booleanElement((row.override_on || []).length)}</TableCell>
                    <TableCell>{formatDateTime(row.created_at)}</TableCell>
                    <TableCell>{formatDateTime(row.updated_at)}</TableCell>
                    <TableCell>{formatDateTime(row.updated_at_advanced)}</TableCell>
                    <TableCell>{formatDateTime(row.updated_at_preferred)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/foh-studios/${row._id}/edit`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onPageChange={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default PromoTable
