import moment from 'moment'
import { saveAs } from 'file-saver'
import { stringify } from 'csv-stringify/lib/sync'
import { downloadParticipantsPromise } from 'api/Challenge'

const columns = [
  { key: 'userId', header: 'User ID' },
  { key: 'name', header: 'Name' },
  { key: 'email', header: 'Email' },
  { key: 'countryName', header: 'Country Name' },
  { key: 'countryCode', header: 'Country Code' },
  { key: 'completedWorkoutCount', header: 'Completed Workout Count' },
  { key: 'completedWorkoutIDs', header: 'Array of Completed Workout IDs' },
  { key: 'completedActivityCount', header: 'Completed Activity Count' },
  { key: 'completedActivityIDs', header: 'Array of Completed Activity IDs' },
  { key: 'totalWorkoutCount', header: 'Total Workout Count' },
  { key: 'totalSteps', header: 'Total Steps' },
  { key: 'joinDate', header: 'Join Date' },
  { key: 'finishDate', header: 'Finish Date'},
]

export async function downloadParticipants (challenge) {
  downloadParticipantsPromise({ id: challenge.id })
  .then(resp => {
    resp.forEach(r => ['joinDate', 'finishDate'].forEach(k => r[k] = r[k] && moment.tz(r[k], 'Etc/UTC').format('YYYY-MM-DD HH:mm:ss')))
    const output = stringify(resp, { header: true, columns })
    const blob = new Blob([output], { type: 'text/csv;charset=utf-8;' })
    saveAs(blob, `participants-${challenge.id}.csv`)
  })
}
