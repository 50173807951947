import QualityScoreBoostForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { getQualityScoreBoostDetailPromise } from 'api/QualityScoreBoost'

export default function (props) {
  const [data, setData] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getQualityScoreBoostDetailPromise(id)
      .then(setData)
  }, [id])
  return (
    <QualityScoreBoostForm { ...props } isEdit={true} data={data} />
  )
}
