import _ from 'lodash'
import FindSimilarTable from './table'
import FindSimilarToolBar from './toolbar'
import { useLocation } from 'react-router-dom'
import { findSimilarUserPromise } from 'api/User'
import React, { useState, useEffect, useCallback } from 'react'

export default function (props) {
  const location = useLocation()
  const [users, setUsers] = useState([])
  const [params, setParams] = useState({})

  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())
    setParams(too)
  }, [location.search])

  const handleParamsChange = useCallback((payload) => {
    setParams(params => ({ ...params, ...payload }))
  }, [])

  function handleSearch () {
    const too = _.pickBy(params, _.identity)
    props.history.push(`?${new URLSearchParams(too)}`)

    findSimilarUserPromise({
      userId: params.userId,
      possibleNames: params.possibleNames && params.possibleNames.split(',').map(r => r.trim()),
      possibleEmails: params.possibleEmails && params.possibleEmails.split(',').map(r => r.trim()),
    }).then(resp => setUsers(resp))
  }

  return (
    <div className={'root'}>
      <FindSimilarToolBar {...props} params={params} onParamsChange={handleParamsChange} onSearch={handleSearch} />
      <div className={'content'}>
        <FindSimilarTable {...props} data={users} />
      </div>
    </div>
  )
}
