import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'

import {
  Dashboard as DashboardView,
  UserList as UserListView,
  Settings as SettingsView,
  Login as SignInView,
  NotFound as NotFoundView,
  UserDetail as UserDetailView,
  UserEdit as UserEditView,
  PromoList as PromoListView,
  PromoAdd as PromoAddView,
  PromoEdit as PromoEditView,
  PromoBulkAdd as PromoBulkAddView,
  PurchaseList as PurchaseListView,
  PurchaseEditView,
  WorkoutResourceList as WorkoutResourceListView,
  WorkoutResourceAdd as WorkoutResourceAddView,
  WorkoutResourceEdit as WorkoutResourceEditView,
  WorkoutCategoryList as WorkoutCategoryListView,
  WorkoutCategoryAdd as WorkoutCategoryAddView, 
  WorkoutCategoryEdit as WorkoutCategoryEditView,
  WorkoutTrendingList as WorkoutTrendingListView,
  WorkoutTrendingAdd as WorkoutTrendingAddView,
  WorkoutTrendingEdit as WorkoutTrendingEditView,
  WorkoutDailyfixList as WorkoutDailyfixListView,
  WorkoutDailyfixAdd as WorkoutDailyfixAddView,
  WorkoutDailyfixEdit as WorkoutDailyfixEditView,
  WorkoutEquipmentList as WorkoutEquipmentListView,
  WorkoutEquipmentAdd as WorkoutEquipmentAddView,
  WorkoutEquipmentEdit as WorkoutEquipmentEditView,
  MealList as MealListView,
  MealAdd as MealAddView,
  MealEdit as MealEditView,
  MealCategoryList as MealCategoryListView,
  MealCategoryAdd as MealCategoryAddView,
  MealCategoryEdit as MealCategoryEditView,
  MealIngredientCategoryList as MealIngredientCategoryListView,
  MealIngredientCategoryAdd as MealIngredientCategoryAddView,
  MealIngredientCategoryEdit as MealIngredientCategoryEditView,
  MealIngredientPreparationList as MealIngredientPreparationListView,
  MealIngredientPreparationAdd as MealIngredientPreparationAddView,
  MealIngredientPreparationEdit as MealIngredientPreparationEditView,
  MealIngredientList as MealIngredientListView,
  MealIngredientAdd as MealIngredientAddView,
  MealIngredientEdit as MealIngredientEditView,
  MealIngredientUnitList as MealIngredientUnitListView,
  MealIngredientUnitAdd as MealIngredientUnitAddView,
  MealIngredientUnitEdit as MealIngredientUnitEditView,
  MealIngredientCategoryGroceryList as MealIngredientCategoryGroceryListView,
  MealIngredientCategoryGroceryAdd as MealIngredientCategoryGroceryAddView,
  MealIngredientCategoryGroceryEdit as MealIngredientCategoryGroceryEditView,
  MealPlanList as MealPlanListView,
  MealPlanAdd as MealPlanAddView,
  MealPlanEdit as MealPlanEditView,
  PartnerWorkoutResourceList as PartnerWorkoutResourceListView,
  PartnerWorkoutResourceAdd as PartnerWorkoutResourceAddView,
  PartnerWorkoutResourceEdit as PartnerWorkoutResourceEditView,
  PartnerWorkoutCategoryList as PartnerWorkoutCategoryListView,
  PartnerWorkoutCategoryAdd as PartnerWorkoutCategoryAddView,
  PartnerWorkoutCategoryEdit as PartnerWorkoutCategoryEditView,
  LogList as LogListView,
  LogDetail as LogDetailView,
  UserReportList as UserReportListView,
  UserFeedbackList as UserFeedbackListView,
  SocialFeedPostAndCommentList as SocialFeedPostAndCommentListView,
  FeatureBannerList as FeatureBannerView,
  FeatureBannerDetail as FeatureBannerDetailView,
  FeatureBannerCampaignAdd as FeatureBannerCampaignAddView,
  FeatureBannerCampaignEdit as FeatureBannerCampaignEditView,
  FeatureBannerContentList as FeatureBannerContentListView,
  FeatureBannerContentAdd as FeatureBannerContentAddView,
  FeatureBannerContentEdit as FeatureBannerContentEditView,
  FunctionSwitchList as FunctionSwitchView,
  FunctionSwitchEdit as FunctionSwitchEditView,
  RequestBlacklist as RequestBlacklistView,
  RequestBlacklistCreate as RequestBlacklistCreateView,
  SubscriptionStatusRetrieve as SubscriptionStatusRetrieveView,
  CourseVideoUpload as CourseVideoUploadView,
  SocialFeedPostOrCommentDetail,
  CourseWebsiteListView,
  CourseWebsiteUpsertView,
  CourseWeekUpsertView,
  CourseWeekDetailView,
  CourseWeekListView,
  CourseVideoListView,
  CourseVideoUpsertView,
  SocialGroupListView,
  SocialGroupUpsertView,
  CourseUpsertView,
  CourseListView,
  ProgramListView,
  ProgramUpsertView,
  ThemeListView,
  ThemeUpsertView,
  ThemeRelationEditView,
  VideoUpload2View,
  UserCourseListView,
  AddUserToCourseView,
  UpdateUserCourseView,
  WorkoutResourceVersionListView,
  WorkoutResourceVersionDetailView,
  UserSocialView,
  userSocialFeedPostListView,
  userSocialFeedCommentListView,
  BusinessGroupListView,
  BusinessGroupUpsertView,
  CorporateWellnessListView,
  CorporateWellnessAddView,
  CorporateWellnessEditView,
  WorkoutCaptionView,
  VideoResourceListView,
  VideoResourceAddView,
  VideoResourceEditView,
  ProgramWorkoutListView,
  ProgramWorkoutAddView,
  ProgramWorkoutEditView,
  TrainerListView,
  TrainerEditView,
  TrainerAddView,
  ThemeRelationListView,
  ThemeRelationAddView,
  ProgramWeekEditView,
  ProgramWeekAddView,
  ProgramWeekListView,
  SocialFilterListView,
  SocialFilterAddView,
  SocialFilterEditView,
  AdminListView,
  AdminEditView,
  UserProgramListView,
  AddUserToProgramView,
  TranslationItemRelationView,
  UploadCenterView,
  EditUserProgramView,
  ProductListView,
  ProductAddView,
  ProductEditView,
  FeatureBannerPlacementListView,
  FeatureBannerPlacementAddView,
  FeatureBannerPlacementEditView,
  TemplateInviteListView,
  TemplateInviteAddView,
  TemplateInviteEditView,
  SocialFeedReportListView,
  SocialFeedReportDetailView,
  MoveResourceListView,
  MoveResourceEditView,
  MoveResourceAddView,
  MoveWorkoutListView,
  MoveWorkoutEditView,
  MoveWorkoutAddView,
  MoveWorkoutCropCoverView,
  ConvertMoveResourceVideoView,
  BusinessGroupDetailView,
  ChallengeListView,
  ChallengeAddView,
  ChallengeEditView,
  ChallengeDetailView,
  SocialBadgeAddView,
  SocialBadgeEditView,
  SocialBadgeListView,
  UserSocialBadgeAddView,
  UserSocialBadgeListView,
  UserSocialBadgeEditView,
  BusinessUserListView,
  BusinessUserEditView,
  BusinessUserAddView,
  ProgramNutritionListView,
  ProgramNutritionAddView,
  ProgramNutritionEditView,
  BusinessLinkAccountsView,
  FlexNetworkMerchantListView,
  FlexNetworkMerchantUpsertView,
  FlexCardEventListView,
  FlexCardEventDetailView,
  FohStudioListView,
  FohStudioUpsertView,
  ProgramToolListView,
  ProgramToolAddView,
  ProgramToolEditView,
  FeatureSetListView,
  FeatureSetEditView,
  FeatureSetAddView,
  SkuCategoryAddView,
  SkuCategoryListView,
  SkuCategoryEditView,
  SkuFeatureRelationAddView,
  SkuFeatureRelationEditView,
  SkuFeatureRelationListView,
} from './views'
import FeatureSetComponentListView from './views/Product/FeatureSetComponent/List'
import FeatureSetComponentAddView from './views/Product/FeatureSetComponent/Upsert/add'
import FeatureSetComponentEditView from './views/Product/FeatureSetComponent/Upsert/edit'
import WorkoutChapterListView from './views/Workout/WorkoutChapter/List'
import WorkoutChapterEditView from './views/Workout/WorkoutChapter/Upsert/edit'
import RecipeTagListView from './views/Meal/RecipeTag/List'
import RecipeTagAddView from './views/Meal/RecipeTag/Upsert/add'
import RecipeTagEditView from './views/Meal/RecipeTag/Upsert/edit'
import RecipeTagOptionListView from './views/Meal/RecipeTagOption/List'
import RecipeTagOptionAddView from './views/Meal/RecipeTagOption/Upsert/add'
import RecipeTagOptionEditView from './views/Meal/RecipeTagOption/Upsert/edit'
import SegmentTargetingListView from './views/Workout/SegmentTargeting/List'
import SegmentTargetingAddView from './views/Workout/SegmentTargeting/Upsert/add'
import SegmentTargetingEditView from './views/Workout/SegmentTargeting/Upsert/edit'
import QualityScoreBoostListView from './views/Workout/QualityScoreBoost/List'
import QualityScoreBoostAddView from './views/Workout/QualityScoreBoost/Upsert/add'
import QualityScoreBoostEditView from './views/Workout/QualityScoreBoost/Upsert/edit'
import FlexWhiteListView from './views/Studio/FlexWhitelist/List'
import FindSimilarView from './views/User/FindSimilar'
import CompressImageView from './views/Util/CompressImage'

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={UserDetailView}
        exact
        layout={MainLayout}
        path="/user/:userId"
      />
      <RouteWithLayout
        component={UserEditView}
        exact
        layout={MainLayout}
        path="/user/edit/:userId"
      />
      <RouteWithLayout
        component={UserReportListView}
        exact
        layout={MainLayout}
        path="/user-reports"
      />
      <RouteWithLayout
        component={UserFeedbackListView}
        exact
        layout={MainLayout}
        path="/user-feedbacks"
      />
      <RouteWithLayout
        component={userSocialFeedPostListView}
        exact
        layout={MainLayout}
        path="/user-social-feed-post"
      />
      <RouteWithLayout
        component={userSocialFeedCommentListView}
        exact
        layout={MainLayout}
        path="/user-social-feed-comment"
      />
      <RouteWithLayout
        component={UserSocialView}
        exact
        layout={MainLayout}
        path="/user-social"
      />
      <RouteWithLayout
        component={SocialFeedPostOrCommentDetail}
        exact
        layout={MainLayout}
        path="/user-feed-post-or-comment/:id"
      />
      <RouteWithLayout
        component={PromoListView}
        exact
        layout={MainLayout}
        path="/promos"
      />
      <RouteWithLayout
        component={PromoEditView}
        exact
        layout={MainLayout}
        path="/promo/edit/:promoId"
      />
      <RouteWithLayout
        component={PromoAddView}
        exact
        layout={MainLayout}
        path="/promo/add"
      />
      <RouteWithLayout
        component={PromoBulkAddView}
        exact
        layout={MainLayout}
        path="/promo/add-in-bulk"
      />
      <RouteWithLayout
        component={PurchaseListView}
        exact
        layout={MainLayout}
        path="/purchases"
      />
      <RouteWithLayout
        component={PurchaseEditView}
        exact
        layout={MainLayout}
        path="/purchases/edit/:id"
      />
      <RouteWithLayout
        component={WorkoutResourceListView}
        exact
        layout={MainLayout}
        path="/workout-resources"
      />
      <RouteWithLayout
        component={WorkoutResourceAddView}
        exact
        layout={MainLayout}
        path="/workout-resource/add"
      />
      <RouteWithLayout
        component={WorkoutResourceEditView}
        exact
        layout={MainLayout}
        path="/workout-resource/add"
      />
      <RouteWithLayout
        component={WorkoutResourceEditView}
        exact
        layout={MainLayout}
        path="/workout-resource/edit/:id"
      />
      <RouteWithLayout
        component={WorkoutCategoryListView}
        exact
        layout={MainLayout}
        path="/workout-categories"
      />
      <RouteWithLayout
        component={WorkoutCategoryAddView}
        exact
        layout={MainLayout}
        path="/workout-category/add"
      />
      <RouteWithLayout
        component={WorkoutCategoryEditView}
        exact
        layout={MainLayout}
        path="/workout-category/edit/:id"
      />

      <RouteWithLayout
        component={WorkoutTrendingListView}
        exact
        layout={MainLayout}
        path="/workout-trendings"
      />
      <RouteWithLayout
        component={WorkoutTrendingAddView}
        exact
        layout={MainLayout}
        path="/workout-trending/add"
      />
      <RouteWithLayout
        component={WorkoutTrendingEditView}
        exact
        layout={MainLayout}
        path="/workout-trending/edit/:id"
      />

      <RouteWithLayout
        component={WorkoutDailyfixListView}
        exact
        layout={MainLayout}
        path="/workout-dailyfixes"
      />
      <RouteWithLayout
        component={WorkoutDailyfixAddView}
        exact
        layout={MainLayout}
        path="/workout-dailyfix/add"
      />
      <RouteWithLayout
        component={WorkoutDailyfixEditView}
        exact
        layout={MainLayout}
        path="/workout-dailyfix/edit/:id"
      />

      <RouteWithLayout
        component={WorkoutEquipmentListView}
        exact
        layout={MainLayout}
        path="/workout-equipments"
      />
      <RouteWithLayout
        component={WorkoutEquipmentAddView}
        exact
        layout={MainLayout}
        path="/workout-equipment/add"
      />
      <RouteWithLayout
        component={WorkoutEquipmentEditView}
        exact
        layout={MainLayout}
        path="/workout-equipment/edit/:id"
      />

      <RouteWithLayout
        component={PartnerWorkoutResourceListView}
        exact
        layout={MainLayout}
        path="/partner-workout-resources"
      />
      <RouteWithLayout
        component={PartnerWorkoutResourceAddView}
        exact
        layout={MainLayout}
        path="/partner-workout-resource/add"
      />
      <RouteWithLayout
        component={PartnerWorkoutResourceEditView}
        exact
        layout={MainLayout}
        path="/partner-workout-resource/edit/:id"
      />

      <RouteWithLayout
        component={PartnerWorkoutCategoryListView}
        exact
        layout={MainLayout}
        path="/partner-workout-categories"
      />
      <RouteWithLayout
        component={PartnerWorkoutCategoryAddView}
        exact
        layout={MainLayout}
        path="/partner-workout-category/add"
      />
      <RouteWithLayout
        component={PartnerWorkoutCategoryEditView}
        exact
        layout={MainLayout}
        path="/partner-workout-category/edit/:id"
      />

      <RouteWithLayout
        component={MealListView}
        exact
        layout={MainLayout}
        path="/meals"
      />
      <RouteWithLayout
        component={MealAddView}
        exact
        layout={MainLayout}
        path="/meal/add"
      />
      <RouteWithLayout
        component={MealEditView}
        exact
        layout={MainLayout}
        path="/meal/edit/:id"
      />

      <RouteWithLayout
        component={MealCategoryListView}
        exact
        layout={MainLayout}
        path="/meal-categories"
      />
      <RouteWithLayout
        component={MealCategoryAddView}
        exact
        layout={MainLayout}
        path="/meal-category/add"
      />
      <RouteWithLayout
        component={MealCategoryEditView}
        exact
        layout={MainLayout}
        path="/meal-category/edit/:id"
      />

      <RouteWithLayout
        component={MealIngredientCategoryListView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/categories"
      />
      <RouteWithLayout
        component={MealIngredientCategoryAddView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/category/add"
      />
      <RouteWithLayout
        component={MealIngredientCategoryEditView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/category/edit/:id"
      />

      <RouteWithLayout
        component={MealIngredientCategoryGroceryListView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/category-groceries"
      />
      <RouteWithLayout
        component={MealIngredientCategoryGroceryAddView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/category-grocery/add"
      />
      <RouteWithLayout
        component={MealIngredientCategoryGroceryEditView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/category-grocery/edit/:id"
      />

      <RouteWithLayout component={ThemeListView} exact layout={MainLayout} path="/themes" />
      <RouteWithLayout component={ThemeUpsertView} exact layout={MainLayout} path="/themes/add" />
      <RouteWithLayout component={ThemeUpsertView} exact layout={MainLayout} path="/themes/edit/:id" />

      <RouteWithLayout component={ThemeRelationEditView} exact layout={MainLayout} path="/themes/:type/edit/:id" />
      <RouteWithLayout component={ThemeRelationAddView} exact layout={MainLayout} path="/theme-details/:type/add" /> {/* type: item, category, target */}
      <RouteWithLayout component={ThemeRelationListView} exact layout={MainLayout} path="/theme-details/:type" />

      <RouteWithLayout
        component={MealIngredientPreparationListView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/preparations"
      />
      <RouteWithLayout
        component={MealIngredientPreparationAddView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/preparation/add"
      />
      <RouteWithLayout
        component={MealIngredientPreparationEditView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/preparation/edit/:id"
      />

      <RouteWithLayout
        component={MealIngredientListView}
        exact
        layout={MainLayout}
        path="/meal-ingredients"
      />
      <RouteWithLayout
        component={MealIngredientAddView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/add"
      />
      <RouteWithLayout
        component={MealIngredientEditView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/edit/:id"
      />

      <RouteWithLayout
        component={MealIngredientUnitListView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/units"
      />
      <RouteWithLayout
        component={MealIngredientUnitAddView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/unit/add"
      />
      <RouteWithLayout
        component={MealIngredientUnitEditView}
        exact
        layout={MainLayout}
        path="/meal-ingredient/unit/edit/:id"
      />

      <RouteWithLayout
        component={MealPlanListView}
        exact
        layout={MainLayout}
        path="/meal-plans"
      />
      <RouteWithLayout
        component={MealPlanAddView}
        exact
        layout={MainLayout}
        path="/meal-plan/add"
      />
      <RouteWithLayout
        component={MealPlanEditView}
        exact
        layout={MainLayout}
        path="/meal-plan/edit/:id"
      />

      <RouteWithLayout
        component={LogListView}
        exact
        layout={MainLayout}
        path="/logs"
      />
      <RouteWithLayout
        component={LogDetailView}
        exact
        layout={MainLayout}
        path="/log/:id"
      />

      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <RouteWithLayout
        component={FeatureBannerPlacementListView}
        exact
        layout={MainLayout}
        path="/feature-banner/placements"
      />
      <RouteWithLayout
        component={FeatureBannerPlacementAddView}
        exact
        layout={MainLayout}
        path="/feature-banner/placements/add"
      />
      <RouteWithLayout
        component={FeatureBannerPlacementEditView}
        exact
        layout={MainLayout}
        path="/feature-banner/placements/edit/:id"
      />

      <RouteWithLayout
        component={FeatureBannerView}
        exact
        layout={MainLayout}
        path="/feature-banner/campaigns"
      />
      <RouteWithLayout
        component={FeatureBannerCampaignAddView}
        exact
        layout={MainLayout}
        path="/feature-banner/campaigns/add"
      />
      <RouteWithLayout
        component={FeatureBannerDetailView}
        exact
        layout={MainLayout}
        path="/feature-banner/campaigns/:id"
      />
      <RouteWithLayout
        component={FeatureBannerCampaignEditView}
        exact
        layout={MainLayout}
        path="/feature-banner/campaigns/edit/:id"
      />
      <RouteWithLayout
        component={FeatureBannerContentListView}
        exact
        layout={MainLayout}
        path="/feature-banner/contents"
      />
      <RouteWithLayout
        component={FeatureBannerContentAddView}
        exact
        layout={MainLayout}
        path="/feature-banner/contents/add"
      />
      <RouteWithLayout
        component={FeatureBannerContentEditView}
        exact
        layout={MainLayout}
        path="/feature-banner/contents/edit/:id"
      />

      <RouteWithLayout
        component={FunctionSwitchView}
        exact
        layout={MainLayout}
        path="/function-switches"
      />
      <RouteWithLayout
        component={FunctionSwitchEditView}
        exact
        layout={MainLayout}
        path="/function-switches/edit/:id"
      />

      <RouteWithLayout
        component={RequestBlacklistView}
        exact
        layout={MainLayout}
        path="/request-blacklist"
      />
      <RouteWithLayout
        component={RequestBlacklistCreateView}
        exact
        layout={MainLayout}
        path="/request-blacklist/add"
      />

      <RouteWithLayout
        component={SubscriptionStatusRetrieveView}
        exact
        layout={MainLayout}
        path="/subscription-status"
      />

      <RouteWithLayout
        component={TranslationItemRelationView}
        exact
        layout={MainLayout}
        path="/i18n/translation-item-relation"
      />

      <RouteWithLayout
        component={UploadCenterView}
        exact
        layout={MainLayout}
        path="/upload-center"
      />

      <RouteWithLayout
        component={CourseVideoUploadView}
        exact
        layout={MainLayout}
        path="/course-video-upload"
      />

      {/* program start */}
      
      <RouteWithLayout exact layout={MainLayout} component={ProgramListView} path = "/programs" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramUpsertView} path="/programs/create" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramUpsertView} path="/programs/:id/edit" />

      <RouteWithLayout exact layout={MainLayout} component={ProgramWorkoutEditView} path="/program-workouts/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramWorkoutAddView}  path="/program-workouts/add" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramWorkoutListView} path="/program-workouts" />
      <RouteWithLayout exact layout={MainLayout} component={UserProgramListView} path="/user-programs" />
      <RouteWithLayout exact layout={MainLayout} component={EditUserProgramView} path="/user-programs/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={AddUserToProgramView} path="/user-programs/add" />

      <RouteWithLayout exact layout={MainLayout} component={ProgramWeekEditView} path="/program-weeks/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramWeekAddView}  path="/program-weeks/add" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramWeekListView} path="/program-weeks" />

      <RouteWithLayout exact layout={MainLayout} component={ProgramNutritionEditView} path="/program-nutrition/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramNutritionAddView}  path="/program-nutrition/add" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramNutritionListView} path="/program-nutrition" />

      <RouteWithLayout exact layout={MainLayout} component={ProgramToolEditView} path="/program-tools/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramToolAddView}  path="/program-tools/add" />
      <RouteWithLayout exact layout={MainLayout} component={ProgramToolListView} path="/program-tools" />

      {/* program end */}

      {/* course start */}
      <RouteWithLayout exact layout={MainLayout} component={CourseUpsertView} path="/courses/create" />
      <RouteWithLayout exact layout={MainLayout} component={CourseUpsertView} path="/courses/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={CourseListView}   path="/courses" />

      <RouteWithLayout
        component={CourseWebsiteUpsertView}
        exact
        layout={MainLayout}
        path="/course-websites/create"
      />
      <RouteWithLayout
        component={CourseWebsiteUpsertView}
        exact
        layout={MainLayout}
        path="/course-websites/:id/edit"
      />
      <RouteWithLayout
        component={CourseWebsiteListView}
        exact
        layout={MainLayout}
        path="/course-websites"
      />

      <RouteWithLayout
        component={CourseWeekUpsertView}
        exact
        layout={MainLayout}
        path="/course-weeks/create"
      />
      <RouteWithLayout
        component={CourseWeekUpsertView}
        exact
        layout={MainLayout}
        path="/course-weeks/:id/edit"
      />
      <RouteWithLayout
        component={CourseWeekDetailView}
        exact
        layout={MainLayout}
        path="/course-weeks/:id"
      />
      <RouteWithLayout
        component={CourseWeekListView}
        exact
        layout={MainLayout}
        path="/course-weeks"
      />

      {/* video */}
      <RouteWithLayout component={VideoUpload2View} exact layout={MainLayout} path="/videos/upload" />
      <RouteWithLayout component={ConvertMoveResourceVideoView} exact layout={MainLayout} path="/videos/move-resource" />
      <RouteWithLayout component={WorkoutCaptionView} exact layout={MainLayout} path="/videos/workout-caption" />

      <RouteWithLayout exact layout={MainLayout} component={CourseVideoUpsertView} path="/course-videos/create" />
      <RouteWithLayout exact layout={MainLayout} component={CourseVideoUpsertView} path="/course-videos/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={CourseVideoListView}   path="/course-videos" />

      <RouteWithLayout exact layout={MainLayout} component={SocialGroupUpsertView} path="/social-groups/create" />
      <RouteWithLayout exact layout={MainLayout} component={SocialGroupUpsertView} path="/social-groups/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={SocialGroupListView}   path="/social-groups" />

      <RouteWithLayout exact layout={MainLayout} component={UpdateUserCourseView}  path="/user-courses/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={AddUserToCourseView}   path="/user-courses/add"/>
      <RouteWithLayout exact layout={MainLayout} component={UserCourseListView}    path="/user-courses" />

      <RouteWithLayout exact layout={MainLayout} component={VideoResourceEditView}  path="/videos/metadata/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={VideoResourceAddView}  path="/videos/metadata/add"/>
      <RouteWithLayout exact layout={MainLayout} component={VideoResourceListView}    path="/videos/metadata" />
      {/* course end */}

      {/* business start */}
      <RouteWithLayout exact layout={MainLayout} component={BusinessUserListView}  path="/business/users" />
      <RouteWithLayout exact layout={MainLayout} component={BusinessUserEditView}  path="/business/users/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={BusinessUserAddView}  path="/business/users/create" />

      <RouteWithLayout exact layout={MainLayout} component={BusinessGroupListView}  path="/business/groups" />
      <RouteWithLayout exact layout={MainLayout} component={BusinessGroupUpsertView}  path="/business/groups/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={BusinessGroupUpsertView}  path="/business/groups/create" />
      <RouteWithLayout exact layout={MainLayout} component={BusinessGroupDetailView}  path="/business/groups/:id" />

      <RouteWithLayout exact layout={MainLayout} component={BusinessLinkAccountsView}  path="/business/link-accounts" />

      <RouteWithLayout exact layout={MainLayout} component={CorporateWellnessListView}  path="/business/corporate-wellness" />
      <RouteWithLayout exact layout={MainLayout} component={CorporateWellnessEditView}  path="/business/corporate-wellness/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={CorporateWellnessAddView}  path="/business/corporate-wellness/create" />

      <RouteWithLayout exact layout={MainLayout} component={FlexCardEventListView}  path="/business/flex-card-events" />
      <RouteWithLayout exact layout={MainLayout} component={FlexCardEventDetailView}  path="/business/flex-card-events/:id" />
      {/* business end */}

      <RouteWithLayout exact layout={MainLayout} component={WorkoutResourceVersionListView}  path="/workout-resource-versions" />
      <RouteWithLayout exact layout={MainLayout} component={WorkoutResourceVersionDetailView}   path="/workout-resource-versions/:id"/>

      <RouteWithLayout exact layout={MainLayout} component={TrainerListView}  path="/trainers" />
      <RouteWithLayout exact layout={MainLayout} component={TrainerEditView}  path="/trainers/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={TrainerAddView}  path="/trainers/add" />

      <RouteWithLayout exact layout={MainLayout} component={SocialFilterListView}  path="/filters" />
      <RouteWithLayout exact layout={MainLayout} component={SocialFilterEditView}  path="/filters/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={SocialFilterAddView}  path="/filters/add" />

      <RouteWithLayout exact layout={MainLayout} component={TemplateInviteListView}  path="/invite-templates" />
      <RouteWithLayout exact layout={MainLayout} component={TemplateInviteEditView}  path="/invite-templates/edit/:id" />
      <RouteWithLayout exact layout={MainLayout} component={TemplateInviteAddView}  path="/invite-templates/add" />

      <RouteWithLayout exact layout={MainLayout} component={AdminListView}  path="/admins" />
      <RouteWithLayout exact layout={MainLayout} component={AdminEditView}  path="/admins/edit/:id" />

      <RouteWithLayout exact layout={MainLayout} component={FindSimilarView}  path="/users/find-similar" />

      {/* sku management start */}
      <RouteWithLayout exact layout={MainLayout} component={ProductAddView} path="/products/create" />
      <RouteWithLayout exact layout={MainLayout} component={ProductEditView} path="/products/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={ProductListView}   path="/products" />
      {/* sku management end */}

      <RouteWithLayout exact layout={MainLayout} component={SocialFeedReportListView} path="/social-feed/reports" />
      <RouteWithLayout exact layout={MainLayout} component={SocialFeedReportDetailView} path="/social-feed/reports/:id"/>

      <RouteWithLayout exact layout={MainLayout} component={MoveResourceAddView} path="/move-resources/create" />
      <RouteWithLayout exact layout={MainLayout} component={MoveResourceEditView} path="/move-resources/edit/:id"/>
      <RouteWithLayout exact layout={MainLayout} component={MoveResourceListView} path="/move-resources" />

      <RouteWithLayout exact layout={MainLayout} component={MoveWorkoutAddView} path="/move-workouts/create" />
      <RouteWithLayout exact layout={MainLayout} component={MoveWorkoutEditView} path="/move-workouts/edit/:id"/>
      <RouteWithLayout exact layout={MainLayout} component={MoveWorkoutListView} path="/move-workouts" />
      <RouteWithLayout exact layout={MainLayout} component={MoveWorkoutCropCoverView} path="/move-workouts/crop-cover" />

      <RouteWithLayout exact layout={MainLayout} component={ChallengeAddView} path="/challenges/create" />
      <RouteWithLayout exact layout={MainLayout} component={ChallengeEditView} path="/challenges/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={ChallengeListView} path="/challenges" />
      <RouteWithLayout exact layout={MainLayout} component={ChallengeDetailView} path="/challenges/:id" />

      <RouteWithLayout exact layout={MainLayout} component={SocialBadgeAddView} path="/social-badges/create" />
      <RouteWithLayout exact layout={MainLayout} component={SocialBadgeEditView} path="/social-badges/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={SocialBadgeListView} path="/social-badges" />

      <RouteWithLayout exact layout={MainLayout} component={UserSocialBadgeAddView} path="/user-social-badges/create" />
      <RouteWithLayout exact layout={MainLayout} component={UserSocialBadgeEditView} path="/user-social-badges/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={UserSocialBadgeListView} path="/user-social-badges" />

      <RouteWithLayout exact layout={MainLayout} component={FlexNetworkMerchantListView} path="/flex-network/merchants" />
      <RouteWithLayout exact layout={MainLayout} component={FlexNetworkMerchantUpsertView} path="/flex-network/merchants/:id/edit"/>

      <RouteWithLayout exact layout={MainLayout} component={FohStudioListView} path="/foh-studios" />
      <RouteWithLayout exact layout={MainLayout} component={FohStudioUpsertView} path="/foh-studios/:id/edit"/>

      <RouteWithLayout exact layout={MainLayout} component={FeatureSetAddView} path="/feature-sets/create" />
      <RouteWithLayout exact layout={MainLayout} component={FeatureSetEditView} path="/feature-sets/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={FeatureSetListView} path="/feature-sets" />

      <RouteWithLayout exact layout={MainLayout} component={SkuCategoryAddView} path="/sku-categories/create" />
      <RouteWithLayout exact layout={MainLayout} component={SkuCategoryEditView} path="/sku-categories/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={SkuCategoryListView} path="/sku-categories" />

      <RouteWithLayout exact layout={MainLayout} component={SkuFeatureRelationAddView} path="/sku-feature-relations/create" />
      <RouteWithLayout exact layout={MainLayout} component={SkuFeatureRelationEditView} path="/sku-feature-relations/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={SkuFeatureRelationListView} path="/sku-feature-relations" />

      <RouteWithLayout exact layout={MainLayout} component={FeatureSetComponentAddView} path="/feature-set-components/create" />
      <RouteWithLayout exact layout={MainLayout} component={FeatureSetComponentEditView} path="/feature-set-components/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={FeatureSetComponentListView} path="/feature-set-components" />

      <RouteWithLayout exact layout={MainLayout} component={WorkoutChapterEditView} path="/workout-chapters/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={WorkoutChapterListView} path="/workout-chapters" />

      <RouteWithLayout exact layout={MainLayout} component={RecipeTagAddView} path="/recipe-tags/create" />
      <RouteWithLayout exact layout={MainLayout} component={RecipeTagEditView} path="/recipe-tags/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={RecipeTagListView} path="/recipe-tags" />

      <RouteWithLayout exact layout={MainLayout} component={RecipeTagOptionAddView} path="/recipe-tag-options/create" />
      <RouteWithLayout exact layout={MainLayout} component={RecipeTagOptionEditView} path="/recipe-tag-options/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={RecipeTagOptionListView} path="/recipe-tag-options" />

      <RouteWithLayout exact layout={MainLayout} component={SegmentTargetingAddView} path="/workout/segment-targeting/create" />
      <RouteWithLayout exact layout={MainLayout} component={SegmentTargetingEditView} path="/workout/segment-targeting/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={SegmentTargetingListView} path="/workout/segment-targeting" />

      <RouteWithLayout exact layout={MainLayout} component={QualityScoreBoostAddView} path="/workout/quality-score-boosts/create" />
      <RouteWithLayout exact layout={MainLayout} component={QualityScoreBoostEditView} path="/workout/quality-score-boosts/:id/edit"/>
      <RouteWithLayout exact layout={MainLayout} component={QualityScoreBoostListView} path="/workout/quality-score-boosts" />
      
      <RouteWithLayout exact layout={MainLayout} component={FlexWhiteListView} path="/flex-whitelist" />

      <RouteWithLayout exact layout={MainLayout} component={CompressImageView} path="/util/compress-images" />

      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
