import _ from 'lodash'
import { send } from 'helpers/utils'
import React, { useCallback, useMemo } from 'react'
import Card from '@material-ui/core/Card/Card'
import { Button, Grid } from '@material-ui/core'
import { syncFohStudioPromise } from 'api/studio'
import FormTextField from 'components/FormTextField'

export function Overview (props) {
  const { data, studio } = props
  const readonlyKeys = ['foh_studio_id', 'google_place_id', 'name', 'street_primary', 'city', 'state', 'zipcode', 'longitude', 'latitude']

  const points = useMemo(() => {
    const [longitude, latitude] = send(studio.location, 'coordinates') || []
    return { longitude, latitude }
  }, [studio.location])

  const handleSync = useCallback(() => syncFohStudioPromise({ id: data.id }), [data.id])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>OVERVIEW</label>
      
      {
        data.id && <Button color='secondary' size='small' variant='contained' onClick={e => {
          e.stopPropagation()
          handleSync().then(() => window.location.reload())
        }}> Sync </Button>
      }
      
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      {
        readonlyKeys.map(key => <Grid item xs={6} key={key}>
          <div className='form-input-elem' key={key}>
            <FormTextField name={key} label={_.startCase(key)} value={studio[key] || points[key]} readonly={true} onChange={() => {}} />
          </div>
        </Grid>)
      }
    </Grid>
  </Card>
}