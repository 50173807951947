import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { parse } from 'csv-parse/lib/sync'

export default function ImportFromCSV (props) {
  const { setRecords } = props

  const handleFileUpload = useCallback(event => {
    const files = event.target.files

    if (!files || files.length < 1) return

    const file = event.target.files && event.target.files[0]
    if (!file) return

    var reader = new FileReader()
    reader.onload = () => setRecords(parse(reader.result, { columns: true, skip_empty_lines: true }))
    reader.readAsText(file)
  }, [setRecords])

  return <Button color='secondary' type='button' variant='contained' component='label'>
    Import from CSV
    <input hidden type='file' accept='.csv' value={[]} onChange={handleFileUpload} />
  </Button>
}