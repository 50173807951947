import React from 'react'
import { Delete } from '@material-ui/icons'
import { Card, CardContent, Grid, TextField } from '@material-ui/core'

const PromoTable = props => {
  const { isEditing, whitelist, handleDelete, handleChange } = props

  return (
    <Card>
      <CardContent className={'table-content'}>
        <Grid container spacing={5}>
          {
            Object.entries(whitelist).map(([key, val]) =>
              <Grid key={`first-${key}`} item container spacing={1}>
                <Grid container item key={`second-${key}`} xs={12} className='bottom-color little-title' justifyContent='space-between'>
                  <Grid item xs={2}>{key}</Grid>

                  <Grid item xs={10}>
                    { isEditing && <TextField fullWidth variant='outlined' size='small' placeholder={`${key}: val1,val2,...`} inputProps={{ 'data-lpignore': true }} onChange={event => handleChange(key, event.target.value)} /> }
                  </Grid>
                </Grid>

                { val.map((v, idx) => <Grid item key={`v-${v}-${idx}`} xs={4}>
                  <span>
                    {v} {isEditing && <Delete style={{marginBottom: '-0.3em'}} onClick={() => handleDelete(key, v)} />}
                  </span>
                </Grid>) }
              </Grid>
            )
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default PromoTable
