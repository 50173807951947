import React, { useCallback } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'

export default function ProgramNutritionToolbar (props) {
  const { params, onSearch, onParamsChange } = props

  const handleSearchClick = useCallback((e) => {
    e.stopPropagation()
    onSearch()
  }, [onSearch])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='User ID' size='small' className='background-color' value={params.userId}
            onChange={event => onParamsChange({ userId: event.target.value })} />
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='Possible Emails' size='small' className='background-color' value={params.possibleEmails}
            onChange={event => onParamsChange({ possibleEmails: event.target.value })} />
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth variant='outlined' label='Possible Names' size='small' className='background-color' value={params.possibleNames}
            onChange={event => onParamsChange({ possibleNames: event.target.value })} />
        </Grid>

        <Grid container item xs={2} justifyContent={'flex-end'}>
          <Button disabled={!params.userId} color='primary' type='submit' style={{marginRight: 0}} variant='contained' onClick={handleSearchClick}>
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
