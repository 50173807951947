import QS from 'qs'
import _ from 'lodash'
import uuid from 'uuid'
import React from 'react'
import { MD5 } from 'crypto-js'
import moment from 'moment-timezone'
import { getLocaleCountries } from 'api/helper'
import { getEnumOptionsPromise } from 'api/Util'
import { OSTypeInverted, S3Path } from 'common/enum'

export const formatAgeFromDOB = dob => {
  const _y = Math.floor(moment.duration(Date.now() - dob).years() / 10) * 10
  return _y ? _y + 's' : ''
}

export const formatDateTimeUTC = (dt) => {
  if (!dt) return dt

  return moment.tz(dt, 'Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
}

export function platformHumanize (platform) {
  const platformArray = JSON.parse(platform || '[]')
  return platformArray.map(p => OSTypeInverted[p]).join(', ')
}

export function send (obj, ...messages) {
  for (const msg of messages) {
    if (_.isUndefined(obj) || _.isNull(obj)) return obj

    const [fn, ...params] = [].concat(msg)
    obj = _.isFunction(obj[fn]) ? obj[fn](params) : obj[fn]
  }

  return obj
}

// production, development
// staging, prod
export function checkEnvironment (...environments) {
  const env = process.env.REACT_APP_ENVIRONMENT || 'dev'

  return environments.includes(env)
}

export function formatDateTimeToUTC (dt) {
  if (!dt || !moment(dt).isValid()) return dt

  return moment.tz(dt, 'Etc/UTC').format('YYYY-MM-DD HH:mm:ss z')
}

export function trimString (val) {
  if (!_.isString(val)) return val

  return _.trim(val)
}

export function findTargetFromOptions (options, { label, value }) {
  const [_key, _value] = label ? ['label', 'value'] : ['value', 'label']

  return (options.find(option => option[_key] === (label || value)) || {})[_value]
}

export function uuidV4 () {
  return uuid.v4()
}

export function randString () {
  const code = MD5(`${uuidV4()}`).toString()

  return code.substring(0, 12)
}

export function boolStr (val) {
  return val ? 'TRUE' : 'FALSE'
}

export function isHttpUrl (link) {
  const regex = /^https?:\/\//i
  return regex.test(link)
}

export async function getImageInfo (uri) {
  if (!uri) return {}

  return new Promise((resolve) => {
    const img = new Image()
    img.onload = function () { resolve(this) }
    img.src = isHttpUrl(uri) ? uri : URL.createObjectURL(uri)
  })
}

// 200*200 or 200x200
export function splitWidthAndHeight (str) {
  if (!str) return []
  const [, width, height] = str.match(/(\d+)[*|x](\d+)/)

  return [width, height]
}

export function assembleI18nImageSrc ({ bucket, path }) {
  return `${S3Path}/${path}?dt=${Date.now()}`
}

export const booleanElement = val => {
  if (val === null || val === undefined) return 'Null'

  return val
    ? <span className='color-green'>True</span>
    : <span className='color-red'>False</span>
}

export async function getLocaleCountryOptions () {
  const result = [{ label: 'USA', value: 'US' }]
  const countries = await getLocaleCountries()
  countries.forEach(c => c.code === 'US' || result.push({ label: c.name, id: c.id, value: c.code }))

  return _.uniqBy(result, 'value')
}

QS.myParse = function (data, isUseDefaultDecoder = true) {
  return QS.parse(data, {
    ignoreQueryPrefix: true,
    decoder (str, defaultDecoder, charset, type) {
      if (isUseDefaultDecoder) return defaultDecoder(str, defaultDecoder, charset, type)

      if (type === 'key')
        return defaultDecoder(str, defaultDecoder, charset, type)

      if (/^(\d+|\d*\.\d+)$/.test(str)) return parseFloat(str)

      let keywords = { true: true, false: false, null: null, undefined: undefined }
      if (_.has(keywords, str)) return keywords[str]
      
      return defaultDecoder(str, defaultDecoder, charset, type)
    }
  })
}

export function tryParseJson(jsonStr, defaultVal){
  try {
    return JSON.parse(jsonStr)
  } catch (error) {
    return defaultVal
  }
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function convertMapToOption (map) {
  return _.map(map, (value, label) => ({ label, value }))
}

export async function genOptionsFromEnum (enums) {
  const resp = await getEnumOptionsPromise(enums)

  return _.reduce(resp, (res, value, label) => ({
    ...res, [label]: _.map(value, (value, label) => ({ label, value }))
  }), {})
}

export function parseUrl (url) {
  const parsed = new URL(url)

  return {
    host: parsed.host,
    path: parsed.pathname,
    baseUrl: `${parsed.protocol}//${parsed.host}${parsed.pathname}`,
  }
}