import _ from 'lodash'
import 'assets/scss/form2.scss'
import { AutoCompleteNew } from 'components/Select'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { getFeatureSetList, getSkuCategoryList, upsertSkuFeatureRelationRoute } from 'api/Product'
import { ParamsForOption } from 'common/enum'

export default function SkuFeatureRelationForm (props) {
  const { data, history } = props
  const [payload, setPayload] = useState({})
  const [featureSets, setFeatureSets] = useState([])
  const [productSkuCategories, setProductSkuCategories] = useState([])
  const columnsNotNull = []

  const optionGen = function (list, lKey = 'name', vKey = 'id') {
    return list.map(l => ({ label: l[lKey], value: l[vKey] }))
  }

  useLayoutEffect(() => {
    getFeatureSetList(ParamsForOption).then(optionGen).then(setFeatureSets)
    getSkuCategoryList(ParamsForOption).then(optionGen).then(setProductSkuCategories)
  }, [])

  useLayoutEffect(() => {
    if (!_.isEmpty(payload)) return

    setPayload(data)
  }, [payload, data])

  const handleChange = useCallback((key, val) => {
    setPayload(prev => ({ ...prev, [key]: val }))
  }, [])

  const cancelClicked = useCallback(() => {
    history.push('/sku-feature-relations')
  }, [history])

  const saveClicked = useCallback(() => {
    upsertSkuFeatureRelationRoute(payload)
      .then(() => history.push('/sku-feature-relations'))
  }, [payload, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!payload[cname]) return true
  }, [payload, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Sku Feature Relation
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
    <Card>
      <Grid item xs={12} className='form-card-title'>
        <label className='form-card-label'>SETUP</label>
        <Switch id='active' name={'active'} checked={Boolean(payload.active)} onChange={e => handleChange('active', e.target.checked)} />
      </Grid>

      <Grid container item xs={12} className='form-card-content' >
        <Grid item xs={12}>
          <label className='full-width'>Sku Category</label>
          <AutoCompleteNew disableUnderline={false} value={payload.sku_category_id} options={productSkuCategories} inputLeft={true} variant='outlined' className='filter background-color'
            onChange={(event, selected) => handleChange('sku_category_id', selected && selected.value)} />
        </Grid>

        <Grid item xs={12}>
          <label className='full-width'>Feature Set</label>
          <AutoCompleteNew disableUnderline={false} value={payload.feature_set_id} options={featureSets} inputLeft={true} variant='outlined' className='filter background-color'
            onChange={(event, selected) => handleChange('feature_set_id', selected && selected.value)} />
        </Grid>

        <Grid item xs={12}>
          <label className='full-width'>Sequence</label>
          <NumberAutoComplete variant='outlined' name='sequence' inputLeft={true} from={1} to={100} value={payload.sort} handleSelectChange={(val) => handleChange('sort', val)} />
        </Grid>

      </Grid>
    </Card>
    </div>
  </div>
}
