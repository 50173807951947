import 'assets/scss/form2.scss'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import FormTextField from 'components/FormTextField'
import { upsertRecipeTagOption } from 'api/RecipeTag'
import JSONEditor from 'components/JSONEditor'

export default function (props) {
  const { data, history } = props
  const [jsonGetter, setJsonGetter] = useState()
  const [recipeTagOption, setRecipeTagOption] = useState({})
  const columnsNotNull = ['name', 'recipe_tag_id', 'sort']

  useLayoutEffect(() => {
    if (data) setRecipeTagOption(data)
  }, [data])

  const handleChange = useCallback((key, val) => {
    setRecipeTagOption({ ...recipeTagOption, [key]: val })
  }, [recipeTagOption])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertRecipeTagOption({ ...recipeTagOption, rule: jsonGetter() })
      .then(() => history.goBack())
  }, [recipeTagOption, jsonGetter, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!recipeTagOption[cname]) return true
  }, [recipeTagOption, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Recipe Tag Option
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Card>
          <Grid item xs={12} className='form-card-title'>
            <label className='form-card-label'>SETUP</label>
            <Switch id='active' name={'active'} checked={Boolean(recipeTagOption.active)} onChange={e => handleChange('active', e.target.checked)} />
          </Grid>
          
          <Grid container item xs={12} className='form-card-content'>
            <Grid item xs={6}>
              <FormTextField label='Name' name='name' value={recipeTagOption.name} onChange={handleChange} />
            </Grid>

            <Grid item xs={6}>
              <div className='form-input-elem'>
                <label className='full-width'>Recipe Tag</label>
                <AutoCompleteNew inputLeft variant='outlined' dbName='recipe_tag' value={recipeTagOption.recipe_tag_id}
                  onChange={(event, selected) => handleChange('recipe_tag_id', selected && selected.value)} />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='form-input-elem'>
                <label className='full-width'>Sort</label>
                <NumberAutoComplete name='sequence' from={1} to={100} value={recipeTagOption.sort} inputLeft={true}
                  handleSelectChange={value => handleChange('sort', value) } variant='outlined' />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='form-input-elem'>
                <label className='full-width'>Rule</label>
                <JSONEditor height='300px' setJSONGetter={setJsonGetter} initValue={recipeTagOption.rule || {}} />
              </div>
            </Grid>

          </Grid>
        </Card>

        </Grid>
      </Grid>
    </div>
  </div>
}
