import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'
import _ from 'lodash'

const ProgramNutritionTable = props => {
  const { data, page, onPageChange, recipeTags } = props
  const [recipeTagMap, setRecipeTagMap] = useState({})

  useEffect(() => {
    setRecipeTagMap(_.fromPairs(recipeTags.map(rt => ([rt.value, rt.label]))))
  }, [recipeTags])

  useEffect(() => {
    console.log(recipeTagMap)
  }, [recipeTagMap])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Recipe Tag</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{recipeTagMap[row.recipe_tag_id] || row.recipe_tag_id}</TableCell>
                    <TableCell>{row.sort}</TableCell>
                    <TableCell>
                      { booleanElement(row.active) }
                    </TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/recipe-tag-options/${row.id}/edit`} />
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramNutritionTable