import { Card } from '@material-ui/core'
import _ from 'lodash'
import JSONEditor from 'components/JSONEditor'
import { Grid } from '@material-ui/core'
import FormTextField from 'components/FormTextField'
import { MultipleAutoCompleteSelect } from 'components/Select'
import UploaderForSingleImage from 'components/UploaderForSingleImage'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'
import React, { useCallback, useMemo, useState } from 'react'

export default function Setup (props) {
  const { data, studio, setStudio } = props
  const [description, setDescription] = useState()
  const [regularOpeningHours, setRegularOpeningHours] = useState()

  // displayName, regularOpeningHours, rating, websiteURI, editorialSummary
  const editableKeys = useMemo(() => {
    return ['display_name', 'website']
  }, [])

  const OverrideOnOptions = useMemo(() => {
    return editableKeys.concat('rating', 'description', 'regular_opening_hours').map(k => ({ label: _.camelCase(k), value: k }))
  }, [editableKeys])

  const OverrideOnColumns = useMemo(() => {
    return OverrideOnOptions.map(x => x.value)
  }, [OverrideOnOptions])
  
  const handleChange = useCallback((key, val) => {
    if (_.isEqual(studio[key], val)) return

    const payload = { [key]: val }

    if (OverrideOnColumns.includes(key))
      payload.override_on = _.isEqual(data[key], val)  
        ? [..._.pull(studio.override_on, key)]
        : _.uniq((studio.override_on || []).concat(key))

    setStudio(prev => ({ ...prev, ...payload }))
  }, [OverrideOnColumns, data, setStudio, studio])

  const handleOverrideOnChange = useCallback(value => {
    setStudio(prev => ({ ...prev, override_on: value }))
  }, [setStudio])

  const handleJsonEditorOnBlur = useCallback((key, getter) => {
    handleChange(key, getter())
  }, [handleChange])

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>SETUP</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      {
        editableKeys.map(key => <Grid item xs={6} key={key}>
          <div className='form-input-elem' key={key}>
            <FormTextField name={key} label={_.startCase(key)} value={studio[key]} onChange={handleChange} />
          </div>
        </Grid>)
      }

      <Grid item xs={6} key={'rating'}>
        <div className='form-input-elem' key='rating'>
          <FormTextField decimalOnly={true} name='rating' label='Rating' value={studio.rating} onChange={handleChange} />
        </div>
      </Grid>

      <Grid item xs={6} key='override_on'>
        <div className='form-input-elem'>
          <label className='full-width'>Override On</label>
          <MultipleAutoCompleteSelect options={OverrideOnOptions} values={studio.override_on || []} className='full-width' variant='outlined'
            onChange={(event, selected) => handleOverrideOnChange(selected.map(s => s.value))}
          />
        </div>
      </Grid>

      <Grid item xs={6} className='bottom-dashed'>
        <BooleanToggleButtonGroup
          label='Google Photos' name='google_photos' withoutNull={true} value={studio.google_photos} onChange={handleChange} />
      </Grid>

      <Grid item xs={12} key='icon' className='bottom-dashed'>
        <div style={{paddingTop: '10px'}}>
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
              <span>Default Photo</span>
            </div>
          </div>

          <div style={{marginTop: '15px'}}>
            <UploaderForSingleImage
              keyName='default_photo'
              fileType='FohStudioPhoto'
              handleChange={handleChange}
              filename={studio.default_photo}
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={6}>
        <Grid item>
          <label className='form-card-label'>Regular Opening Hours</label>
        </Grid>

        <Grid item style={{border: '1px #E5E5E5 solid'}}>
          <JSONEditor
            height='300px'
            setJSONGetter={setRegularOpeningHours}
            initValue={studio.regular_opening_hours || {}}
            handleBlur={() => handleJsonEditorOnBlur('regular_opening_hours', regularOpeningHours)}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid item>
          <label className='form-card-label'>Description</label>
        </Grid>

        <Grid item>
          <JSONEditor
            height='300px'
            setJSONGetter={setDescription}
            initValue={studio.description || {}}
            handleBlur={() => handleJsonEditorOnBlur('description', description)}
          />
        </Grid>
      </Grid>
    </Grid>

  </Card>
}