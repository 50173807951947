import React, { useCallback } from 'react'
import { TextField } from '@material-ui/core'

export default function FormTextField (props) {
  const { onChange, label, placeholder, name, value, numberOnly, decimalOnly, readonly=false } = props

  const handleDataChange = useCallback(event => {
    const val = event.target.value
    if (numberOnly && !/^-?\d*$/.test(val)) return
    if (decimalOnly && !/^-?\d*\.?\d*$/.test(val)) return

    onChange(event.target.name, event.target.value)
  }, [decimalOnly, numberOnly, onChange])

  return <>
    <label className='full-width'>{label}</label>
    <TextField placeholder={placeholder} variant='outlined' size='small' className='full-width' name={name} value={value || ''} onChange={handleDataChange} inputProps={{ 'data-lpignore': true, readOnly: readonly }} />
  </>
}
