import { getApiClient } from './ApiClient'

export const getSegmentTargetingListPromise = (params) => {
  return getApiClient()
    .get('/segment-targeting', { params })
    .then(resp => resp.data)
}

export const getSegmentTargetingDetailPromise = id => {
  return getApiClient()
    .get(`/segment-targeting/${id}`)
    .then(resp => resp.data)
}

export const upsertSegmentTargetingPromise = (body) => {
  return getApiClient()
    .post('/segment-targeting', { body })
    .then(resp => resp.data)
}
