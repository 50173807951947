import SegmentTargetingForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { getSegmentTargetingDetailPromise } from 'api/segmentTargeting'

export default function (props) {
  const [segmentTargeting, setSegmentTargeting] = useState({})
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getSegmentTargetingDetailPromise(id)
      .then(resp => setSegmentTargeting(resp))
  }, [id])
  return (
    <SegmentTargetingForm { ...props } isEdit={true} data={segmentTargeting} />
  )
}
