import Path from 'path'
import Context from 'common/context'
import { Button } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { DropzoneDialog } from 'material-ui-dropzone'
import { compressImage, getUploadUrlSimplePromise, uploadFileToS3 } from 'api/helper'
import { parseUrl } from 'helpers/utils'

const Uploader2 = props => {
  const { bucket, filename, afterUpload, filepath, dropzoneText, isNeedCompress=true } = props
  const { handleNotification } = Context._currentValue
  const [open, setOpen] = useState(false)
  const [files, setFiles] = useState([])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleChange = useCallback(async targets => {
    if (!targets || targets.length <= 0) return
    setFiles(targets)
  }, [])

  const genFilename = useCallback(file => {
    if (!filename) return file.name

    const extname = Path.extname(file.name)
    return `${filename}${extname}`
  }, [filename])

  const handleAfterUpload = useCallback(async params => {
    afterUpload && await afterUpload(params)
  }, [afterUpload])

  const upload = useCallback(async function () {
    try {
      for (const file of files) {
        const fname = genFilename(file)
        const presignedUrls = await getUploadUrlSimplePromise({ bucket, paths: [{ filepath, filename: fname, params: { ACL: 'public-read' } }] })
      
        if (!presignedUrls || presignedUrls.length === 0)
          return handleNotification('error', 'retrieve AWS presigned url failed')
  
        const presignedUrl = presignedUrls[0]
        const res = await uploadFileToS3(presignedUrl.url, file)
        if (res.status !== 200) return handleNotification('error', 'upload file failed')
        isNeedCompress && await compressImage(presignedUrl)
  
        await handleAfterUpload({ originalName: file.name, url: parseUrl(presignedUrl.url).baseUrl })
      }
    } catch (err) {
      handleNotification('error', err.message)
    }
  }, [bucket, filepath, files, genFilename, handleAfterUpload, handleNotification, isNeedCompress])

  const handleSave = useCallback(async () => {
    setOpen(false)
    await upload()
    setFiles([])
  }, [upload])

  return <div>
    <Button variant='outlined' style={{color: '#3F44AB'}} onClick={() => setOpen(true)}>
      Upload
    </Button>

    <DropzoneDialog
      open={open}
      showAlerts={false}
      onSave={handleSave}
      showPreviews={false}
      clearOnUnmount={true}
      onClose={handleClose}
      onChange={handleChange}
      initialFiles={[]}
      showPreviewsInDropzone={true}
      filesLimit={100}
      maxFileSize={30000000000}
      dropzoneText={dropzoneText || 'drag image here or upload from computer'}
    />
  </div>
}

export default Uploader2
