import _ from 'lodash'
import 'assets/scss/form2.scss'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import FormTextField from 'components/FormTextField'
import { upsertQualityScoreBoostPromise } from 'api/QualityScoreBoost'
import { genOptionsFromEnum } from 'helpers/utils'
import { isBlank } from 'common/usual'

export default function (props) {
  const { data, history } = props
  const [payload, setPayload] = useState({})
  const columnsNotNull = ['content_type', 'content_id']
  const [options, setOptions] = useState([])

  useLayoutEffect(() => {
    genOptionsFromEnum('BoostQualityScoreType')
      .then(r => setOptions(r.BoostQualityScoreType))
  }, [])

  useLayoutEffect(() => {
    if (isBlank(payload)) setPayload(data)
  }, [data, payload])

  const handleChange = useCallback((key, value, other) => {
    setPayload(prev => ({ ...prev, [key]: value, ...other }))
  }, [])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertQualityScoreBoostPromise(payload)
      .then(history.goBack)
  }, [history, payload])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!payload[cname]) return true
  }, [payload, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Quality Score Boost
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
              <Switch id='active' name={'active'} checked={Boolean(payload.active)} onChange={e => handleChange('active', e.target.checked)} />
            </Grid>
            
            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Content ID</label>
                  <FormTextField name='content_id' value={payload.content_id} onChange={handleChange} />
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className='form-input-elem'>
                  <label className='full-width'>Content Type</label>
                  <AutoCompleteNew name='content_type' options={options} value={payload.content_type} className='full-width' variant='outlined' inputLeft={true}
                    onChange={(event, selected) => handleChange('content_type', selected && selected.value)}
                  />
                </div>
              </Grid>

              {
                ['ns_f', 'sr_f', 'ns_m', 'sr_m'].map(key =>
                  <Grid item xs={6}>
                    <div className='form-input-elem'>
                      <label className='full-width'>{_.toUpper(key)}</label>
                      <FormTextField name={key} value={payload[key]} decimalOnly={true} onChange={handleChange} />
                    </div>
                  </Grid>
                )
              }
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
