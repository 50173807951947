import _ from 'lodash'
import AddBtn from 'components/AddBtn'
import { isBlankV2 } from 'common/usual'
import React, { useEffect, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { Grid, TextField } from '@material-ui/core'
import ImportFromCSV from 'components/ImportFromCSV'
import { upsertQualityScoreBoostPromise } from 'api/QualityScoreBoost'
import { checkPermission } from 'helpers/permission'

export default function ProgramNutritionToolbar (props) {
  const { params, onParamsChange, options, loadData } = props
  const [records, setRecords] = useState([])

  useEffect(() => {
    if (isBlankV2(records)) return

    const duplicated = _(records)
      .countBy(r => JSON.stringify(_.pick(r, 'content_type', 'content_id')))
      .pickBy(c => c > 1).keys().join('\n')
    if (duplicated) {
      window.confirm(`Duplicated records found in CSV:\n${duplicated}`)
      return
    }

    const payload = records.map(r => ({ active: true, ...r }))
    upsertQualityScoreBoostPromise(payload).then(loadData)
  }, [loadData, records])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField name='value' label='ID or Name' fullWidth variant='outlined' size='small' className='background-color' value={params.keyword} onChange={event => onParamsChange({keyword: event.target.value}, 500)} />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteNew name='type' label='Content Type' disableUnderline={false} value={+params.type} options={options} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({type: selected && selected.value})}
          />
        </Grid>

        <Grid container item xs={6} justifyContent={'flex-end'}>
          <AddBtn id='add-btn' color='primary' type='submit' variant='contained'
              onClick={e => {
                e.stopPropagation()
                props.history.push({ pathname: '/workout/quality-score-boosts/create', state: { params } })
              }}
          > Add </AddBtn>
          &nbsp;
          { checkPermission('120302') && <ImportFromCSV setRecords={setRecords} /> }
        </Grid>
      </Grid>
    </div>
  )
}
