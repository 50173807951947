import WorkoutChapterForm from './form'
import React, { useState, useLayoutEffect } from 'react'
import { getWorkoutChapterDetail } from 'api/WorkoutChapter'

export default function (props) {
  const [workoutChapter, setWorkoutChapter] = useState([])
  const { match: { params: { id } } } = props

  useLayoutEffect(() => {
    getWorkoutChapterDetail(id)
      .then(resp => setWorkoutChapter(resp))
  }, [id])
  return (
    <WorkoutChapterForm workoutId={id} { ...props } isEdit={true} data={workoutChapter} />
  )
}
