import _ from 'lodash'
import Table from './table'
import Toolbar from './toolbar'
import { useLocation } from 'react-router-dom'
import { getEnumOptionsPromise } from 'api/Util'
import React, { useState, useEffect } from 'react'
import { getProgramToolList } from 'api/ProgramTool'

const ProgramToolList = props => {
  const location = useLocation()
  const [type, setType] = useState({})
  const [programWeeks, setProgramWeeks] = useState([])

  useEffect(() => {
    getProgramToolList().then(resp => setProgramWeeks(resp))
    getEnumOptionsPromise('ProgramToolType').then(resp => setType(_.invert(resp.ProgramToolType)))
  }, [location.search])

  return (
    <div className={'root'}>
      <div className={'content'}>
        <Toolbar {...props} />
        <br />
        <Table {...props} type={type} data={programWeeks} />
      </div>
    </div>
  )
}

export default ProgramToolList
