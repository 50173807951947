import { saveAs } from 'file-saver'
import React, { useCallback } from 'react'
import { Card, Grid } from '@material-ui/core'
import { S3BatchUploader } from 'components/S3UploadInput'

export default function FunctionSwitchUpdate (props) {
  const download = useCallback(async payload => {
    return saveAs(payload.url, payload.originalName)
  }, [])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>

        <Grid container spacing={0} >
          <S3BatchUploader bucket='fiton-staging' filepath='compress' filename='foo' afterUpload={download} />
        </Grid>
      </Card>
    </div>
  )
}
