import { getApiClient } from './ApiClient'

// recipe tags
export const getRecipeTagList = (params) => {
  return getApiClient().get('/recipe-tags', { params }).then(resp => resp.data)
}

export const getRecipeTagDetail = (id) => {
  return getApiClient().get(`/recipe-tags/${id}`).then(resp => resp.data)
}

export const upsertRecipeTag = (body) => {
  return getApiClient().post('/recipe-tags', { body }).then(resp => resp.data)
}

// recipe tag options
export const getRecipeTagOptionList = (params) => {
  return getApiClient().get('/recipe-tag-options', { params }).then(resp => resp.data)
}

export const getRecipeTagOptionDetail = (id) => {
  return getApiClient().get(`/recipe-tag-options/${id}`).then(resp => resp.data)
}

export const upsertRecipeTagOption = (body) => {
  return getApiClient().post('/recipe-tag-options', { body }).then(resp => resp.data)
}

