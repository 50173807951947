import React from 'react'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'

const PromoTable = props => {
  const { data, page, onPageChange } = props

  return (
    <Card className={clsx('promo-table')}>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Sort</TableCell>
                  <TableCell>Featured</TableCell>
                  <TableCell>Featured On For You</TableCell>
                  <TableCell>Senior</TableCell>
                  <TableCell>Non Senior</TableCell>
                  <TableCell>Female</TableCell>
                  <TableCell>Male</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Edit</TableCell>
                  {/* <TableCell>Item</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Category</TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(row => (
                  <TableRow key={row.id} hover>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.sort}</TableCell>
                    <TableCell>{booleanElement(row.featured)}</TableCell>
                    <TableCell>{booleanElement(row.featured_for_you)}</TableCell>
                    <TableCell>{booleanElement(row.senior)}</TableCell>
                    <TableCell>{booleanElement(row.non_senior)}</TableCell>
                    <TableCell>{booleanElement(row.female)}</TableCell>
                    <TableCell>{booleanElement(row.male)}</TableCell>
                    <TableCell>{booleanElement(row.active)}</TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/themes/edit/${row.id}`} />
                    </TableCell>
                    {/* <TableCell>
                      <EditBtn history={props.history} path={`/themes/item/edit/${row.id}`} />
                    </TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/themes/target/edit/${row.id}`} />
                    </TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/themes/category/edit/${row.id}`} />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page - 1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default PromoTable
