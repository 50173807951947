import React from 'react'
import AddBtn from 'components/AddBtn'
import { BooleanOptions } from 'common/enum'
import { Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'

export default function WorkoutCategoryToolbar (props) {
  const { params, onParamsChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='ID' size='small' value={params.id} className='background-color' onChange={event => onParamsChange({id: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Name' size='small' value={params.name} className='background-color' onChange={event => onParamsChange({name: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew label='Active' disableUnderline={false} value={params.active} options={BooleanOptions} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({active: selected && selected.value})}
          />
        </Grid>
        <Grid container item xs={6} justify={'flex-end'}>
          <AddBtn
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push('/challenges/create')
            }}
            variant='contained'
          >
            Add Challenge
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
