import { getApiClient } from './ApiClient'

export const getInviteTemplateTypesPromise = (params) => {
  return getApiClient()
    .get('/invite-template/types', { params })
    .then(resp => resp.data)
}

export const getInviteTemplatePromise = (params) => {
  return getApiClient()
    .get('/invite-templates', { params })
    .then(resp => resp.data)
}

export const getInviteTemplateDetailPromise = (id) => {
  return getApiClient()
    .get(`/invite-templates/${id}`)
    .then(resp => resp.data)
}

export const upsertInviteTemplatePromise = (body) => {
  return getApiClient()
    .post('/invite-templates', { body })
    .then(resp => resp.data)
}

export const deleteInviteTemplatePromise = (id) => {
  return getApiClient()
    .delete(`/invite-templates/${id}`)
    .then(resp => resp.data)
}

export const getJshRecipesPromise = (params) => {
  return getApiClient()
    .get('/jsh-recipes', { params })
    .then(resp => resp.data)
}

export const getEnumOptionsPromise = (...names) => {
  return getApiClient()
    .get('/enums', { params: { names } })
    .then(resp => resp.data)
}

export const getDBOptionsPromise = (...names) => {
  return getApiClient()
    .get('/options', { params: { names } })
    .then(resp => resp.data)
}

export const getUtilConfigsPromise = (...names) => {
  return getApiClient()
    .get('/util-configs', { params: { names } })
    .then(resp => resp.data)
}

export const updateUtilConfigPromise = (id, body) => {
  return getApiClient()
    .post(`/util-configs/${id}`, { body })
    .then(resp => resp.data)
}
