/* eslint-disable no-use-before-define */
import './index.scss'
import { isArray } from 'lodash'
import { convertMapToOption } from 'helpers/utils'
import TextField from '@material-ui/core/TextField'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { getDBOptionsPromise, getEnumOptionsPromise } from 'api/Util'
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete'

export default function MultipleAutoCompleteSelect (props) {
  const { options, enumName, dbName, label, placeholder, values, onChange, className, disableUnderline = true, variant='standard', ...rest } = props
  const [localOptions, setLocalOptions] = useState()

  useLayoutEffect(() => {
    if (enumName)
      getEnumOptionsPromise(enumName)
        .then(resp => setLocalOptions(resp && convertMapToOption(resp[enumName])))
    else if (dbName)
      getDBOptionsPromise(dbName)
        .then(resp => setLocalOptions(resp && resp[dbName]))
    else setLocalOptions(options || [])
  }, [dbName, enumName, options])

  const filterOptions = useMemo(() => (
    createFilterOptions({
      limit: 50
    })
  ), [])

  const defaultValues = useMemo(() => {
    const _values = isArray(values) ? values : (values ? [values] : [])
    return (localOptions || []).filter(o => _values.includes(o.value))
  }, [values, localOptions])

  return (
    <Autocomplete
      { ...rest }
      multiple
      disableCloseOnSelect
      id="tags-standard"
      className={`multiple-auto-complete-select ${className}`}
      options={localOptions || []}
      getOptionLabel={(option) => option.label}
      value={defaultValues}
      size={'small'}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            disableUnderline,
          }}
        />
      )}
    />
  )
}