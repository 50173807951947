import UpsertForm from './form'
import React, { useLayoutEffect, useState } from 'react'

export default function (props) {
  const [data, setData] = useState([])

  useLayoutEffect(() => {
    setData({ active: false })
  }, [])

  return (
    <UpsertForm data={data} {...props} />
  )
}
