import React, { useCallback, useState } from 'react'
import MyModal from 'components/MyModal'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { AutoCompleteNew } from 'components/Select'
import { UserGenderOptions } from 'common/enum'
import { createUserPromise } from 'api/User'

export default function UserCreateModal (props) {
  const { modalStatus, setModalStatus, setTrainer } = props
  const [user, setUser] = useState({ role: 2, password: '19d88c8e83ceeb2cbe5b85d216da583c2e58d8c706ff4a948ebac28152e8ed7e', salt: '3430c472ee97498524b97cddd0a9f5be5fa152d280242938b2faf9bac2fca185' })

  const handleSubmit = useCallback(() => {
    createUserPromise(user)
      .then(user => {
        setTrainer({ user_id: user.id })
        setModalStatus(false)
      })
  }, [setModalStatus, setTrainer, user])

  const closeModal = useCallback(() => {
    setModalStatus(false)
  }, [setModalStatus])

  const handleChange = useCallback((pair) => {
    setUser({ ...user, ...pair })
  }, [user])

  return <MyModal open={modalStatus} setOpen={setModalStatus}>
    <Card className={'form-container'}>
      <FormItem name='name' value={user.name} onChange={handleChange} />
      <FormItem name='user_name' value={user.user_name} onChange={handleChange} />

      <FormItem name='gender'>
        <AutoCompleteNew value={user.gender} options={UserGenderOptions}
          onChange={(event, selected) => handleChange({ gender: selected && selected.value })}
        />
      </FormItem>

      {/* <FormItem name='avatar' value={user.avatar} onChange={handleChange} /> */}
      <FormItem name='role'>
        <AutoCompleteNew value={user.role} enumName='UserRole'
          onChange={(event, selected) => handleChange({ role: selected && selected.value })}
        />
      </FormItem>

      <FormItem name='active'>
        <Switch name='active' checked={Boolean(user.active)} onChange= {e => handleChange({ active: e.target.checked })} />
      </FormItem>

      <br />
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <Button fullWidth size='medium' variant='contained' onClick={closeModal}>
            Close
          </Button>
        </Grid>

        <Grid item xs={7}>
          <Button color='primary' fullWidth size='medium' variant='contained' onClick={handleSubmit}>
            SUBMIT
          </Button>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Card>
  </MyModal>
}
