import React from 'react'
import SocialBadgeForm from './form'

export default function ChallengeAdd (props) {
  const data = { active: false }

  return (
    <SocialBadgeForm data={data} {...props} />
  )
}
