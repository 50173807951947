import React from 'react'
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card/Card'
import ReactJson from 'react-json-view'

export default function Overview (props) {
  const { studio } = props

  return <Card>
    <Grid item xs={12} className='form-card-title'>
      <label className='form-card-label'>EV Charging</label>
    </Grid>

    <Grid container item xs={12} className='form-card-content' >
      <ReactJson src={studio.ev_charging || {}} theme='Solarized' />
    </Grid>
  </Card>
}