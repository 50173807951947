import React, { useCallback } from 'react'
import { Add, Remove } from '@material-ui/icons'
import { Button, Grid, TextField } from '@material-ui/core'


export default function (props) {
  const { idx, data, onChange, onAdd } = props

  const handleChange = useCallback((event) => {
    const { name, value } = event.target
    onChange(idx, name, value)
  }, [idx, onChange])

  const handleAdd = useCallback(() => {
    onAdd(idx)
  }, [idx, onAdd])

  const handleDelete = useCallback(() => {
    onChange(idx, 'active', false)
  }, [idx, onChange])

  return <>
    <Grid item xs={2}>
      <TextField fullWidth name='timestamp' variant='outlined' size='small' className='background-color' value={data.timestamp}
        onChange={handleChange} />
    </Grid>

    <Grid item xs={8}>
      <TextField fullWidth name='move_name' variant='outlined' size='small' className='background-color' value={data.move_name}
        onChange={handleChange} />
    </Grid>

    <Grid item xs={2}>
      <Button color='secondary' size='medium' variant='outlined' onClick={handleAdd}> <Add /> </Button>
      &nbsp;&nbsp;
      <Button size='medium' variant='outlined' onClick={handleDelete}> <Remove /> </Button>
    </Grid>
  </>
}
