import './index.scss'
import _ from 'lodash'
import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react'
import { Card, Button, Switch } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { isBlank } from 'common/usual'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

import { ProgramVideoTypes, ProgramVideoTypeOptions } from 'common/enum'
import UploaderForI18nImage2 from 'components/UploaderForI18nImages/index2'
import ActiveSwitch from 'components/ActiveSwitch'
import { AutoCompleteNew, MultipleAutoCompleteSelect } from 'components/Select'
import DateTimeInput from 'components/DateTimeInput'
import { socialGroupOptionPromise } from 'api/Business'
import UploaderForSingleImage from 'components/UploaderForSingleImage'
import { CourseTrainerMultipleAutoComplete } from 'views/concerns/courseOptions'
import { getProgramToolList } from 'api/ProgramTool'

const columnsNeedCheck = [
  { key: 'name'},
  { key: 'deeplink' },
  { key: 'enrollment_begin' },
  { key: 'duration' },
  { key: 'cover_url' },
]

export default function ProgramTableUpsertForm (props) {
  const { mode, data, program, hidden, handleStepChange, handleValueChange } = props
  const [btnStatus, setBtnStatus] = useState(false)
  const [toolOptions, setToolOptions] = useState([])
  const [requiredColumns, setRequiredColumns] = useState([])
  const [isDailyTaskNeed, setIsDailyTaskNeed] = useState(true)
  const [socialGroupOptions, setSocialGroupOptions] = useState([])

  const isNutritionProgramType = useMemo(() => program.type === ProgramVideoTypes.Nutrition, [program.type])

  const disableBtn = useMemo(() => {
    const foo = ['description', 'name']
    const too = !isNutritionProgramType
    ? ['total_count']
    : (isDailyTaskNeed ? ['daily_task', 'daily_reminder_copy'] : [])

    return btnStatus || [...foo, ...too].map(column => isBlank(program[column])).includes(true)
  }, [btnStatus, isDailyTaskNeed, isNutritionProgramType, program])

  useLayoutEffect(() => {
    setIsDailyTaskNeed(mode === 'add' || !!program.daily_task)
  }, [program.daily_task, mode])
  

  function handleSubmitStep1(){
    return handleStepChange('step2')
  }

  const programFormItem = useCallback((label, name, isRequired, props) => {
    if (isRequired && !requiredColumns.includes(name)) setRequiredColumns([...requiredColumns, name])
    return <FormItem
      label={label} name={name} value={program[name]} isRequired={isRequired} {...props}
      onChange={handleValueChange}
    />
  }, [handleValueChange, program, requiredColumns])

  useLayoutEffect(() => {
    socialGroupOptionPromise().then(resp => setSocialGroupOptions(resp.map(foo => ({ label: foo.name, value: foo.id }))))
  }, [])

  
  return (
    <div className={'root'} hidden={hidden}>
      <Card className={'form-container'}>
        {/* { programFormItem('ID', 'id', true, { readonly: !!program.oldId, type: 'number' })} */}
        { programFormItem('Program Name', 'name', true, { placeholder: 'Enter the name of the program' }) }
        {/* { programFormItem('Deeplink', 'deeplink', true) } */}
        { programFormItem('Program Description', 'description', true, { multiline: true, placeholder: 'Enter the Description of the program' }) }
        { programFormItem('Deeplink', 'deeplink', true,)}

        {/* {programFormItem('Enrollment Begin(UTC time)', 'enrollment_begin', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })} */}
        {/* {programFormItem('Enrollment End(UTC time)', 'enrollment_end', false, { placeholder: 'YYYY-MM-DD HH:mm:ss' })} */}

        <FormItem label='Enrollment Begin(UTC)'>
          <DateTimeInput value={program.enrollment_begin} onChange={enrollment_begin => handleValueChange({ enrollment_begin })} />
        </FormItem>
        <FormItem label='Enrollment End(UTC)'>
          <DateTimeInput value={program.enrollment_end} onChange={enrollment_end => handleValueChange({ enrollment_end })} />
        </FormItem>

        <FormItem label={'Type'}>
          <AutoCompleteNew options={ProgramVideoTypeOptions} value={program.type}
            onChange={(event, selected) => handleValueChange({type: (selected || {value: null}).value})}
          />
        </FormItem>

        <FormItem label={'Duration(weeks)'}>
          <NumberAutoComplete name='duration' from={1} to={100} value={program.duration} handleSelectChange={(duration) => { handleValueChange({ duration }) }} {...props} />
        </FormItem>

        <FormItem label={'Sequence'}>
          <NumberAutoComplete name='sequence' from={1} to={100} value={program.sequence} handleSelectChange={(sequence) => { handleValueChange({ sequence }) }} {...props} />
        </FormItem>

        <FormItem label={'Social Group'}>
          <AutoCompleteNew name='social_group_id' value={program.social_group_id} options={socialGroupOptions}
            onChange={(event, selected) => handleValueChange({ social_group_id: selected && selected.value })}
          />
        </FormItem>

        <FormItem label={'Trainer'}>
          <CourseTrainerMultipleAutoComplete name='trainer_name' value={program.trainers} handleSelectChange={(trainers) => handleValueChange({ trainers })} {...props} />
        </FormItem>

        <FormItem label={'Tool'}>
          <MultipleAutoCompleteSelect dbName='program_tool' values={program.programTools}
            onChange={(event, selected) => handleValueChange({ programTools: selected.map(s => s.value) })} />
        </FormItem>

        {
          !isNutritionProgramType && programFormItem('Number of Workouts', 'total_count', !isNutritionProgramType, { placeholder: 'Enter the number of workouts' })
        }

        {
          isNutritionProgramType &&
            <FormItem label='Daily Task'>
              <Switch checked={isDailyTaskNeed} onChange={ e => {
                !e.target.checked && handleValueChange({ daily_task: null })
                setIsDailyTaskNeed(e.target.checked)
              } } />
            </FormItem>
        }
        {
          isNutritionProgramType && isDailyTaskNeed
            && <FormItem label=' ' name='daily_task' placeholder='Daily Task OR First Week Task' value={program.daily_task} isRequired={false} onChange={handleValueChange} />
        }

        {
          isNutritionProgramType && programFormItem('Daily Reminder Copy', 'daily_reminder_copy', isNutritionProgramType && isDailyTaskNeed)
        }

        {/* {programFormItem('Guide PDF', '', false)} */}

        {/* { Uploa1dAndInput('cover_url') } */}

        <FormItem name='senior'>
          <Switch checked={Boolean(program.senior)} onChange={ e => handleValueChange({ senior: e.target.checked }) } />
        </FormItem>
        <FormItem name='non_senior'>
          <Switch checked={Boolean(program.non_senior)} onChange={ e => handleValueChange({ non_senior: e.target.checked }) } />
        </FormItem>
        <FormItem name='female'>
          <Switch checked={Boolean(program.female)} onChange={ e => handleValueChange({ female: e.target.checked }) } />
        </FormItem>
        <FormItem name='male'>
          <Switch checked={Boolean(program.male)} onChange={ e => handleValueChange({ male: e.target.checked }) } />
        </FormItem>

        {
          ['free_user', 'pro_health_user', 'health_plus_user', 'wlc_user', 'is_wlc'].map(name =>
            <FormItem name={name}>
              <Switch checked={Boolean(program[name])} onChange={ e => handleValueChange({ [name]: e.target.checked }) } />
            </FormItem>
          )
        }

        <FormItem name='featured'>
          <Switch id='featured' checked={Boolean(program.featured)} onChange={ e => handleValueChange({ featured: e.target.checked }) } />
        </FormItem>
        
        <FormItem label={'Active'} name={'active'}>
          {/* <Switch
            name={'active'}
            checked={Boolean(program['active'])}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={e => setProgram({...program, active: e.target.checked}) }
          /> */}
          <ActiveSwitch mode={mode} body={program} setBody={handleValueChange} isNeedCheck={true} columns={columnsNeedCheck} />
        </FormItem>

        {/* <UploaderForI18nImage type={S3PreSignType.Program} label='Cover Image' keyName={'cover_url'} data={program} setData={setProgram} /> */}
        <div style={{ width: '80%', maxWidth: '1000px', margin: '30px auto 0' }}>
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
              <span>Background (PNG, JPG)</span>
            </div>
          </div>

          <UploaderForSingleImage keyName='background_url' handleChange={(k, v) => handleValueChange({ [k]: v })} filename={program.background_url} fileType='ProgramBackgroundUrl' />

          <br />

          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
              <span>Guide Cover (PNG, JPG)</span>
            </div>
          </div>

          <UploaderForSingleImage keyName='guide_cover_url' handleChange={(k, v) => handleValueChange({ [k]: v })} filename={program.guide_cover_url} fileType='ProgramGuideCoverUrl' />

          <br />

          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
              <span>Cover Image (PNG, JPG)</span>
            </div>
          </div>
          
          <UploaderForI18nImage2
            fileType='ProgramCoverImage'
            filename={program.cover_url}
            setFilename={fname => handleValueChange({ cover_url: fname })}
          />
        </div>

        <Button fullWidth className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmitStep1} disabled={disableBtn}>
          NEXT
        </Button>
      </Card>
    </div>
  )
}