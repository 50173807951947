import React from 'react'
import { Grid, TextField } from '@material-ui/core'

export default function (props) {
  const { params, onParamsChange } = props

  return (
    <Grid container item xs={12} spacing={2} >
      <Grid item xs={4}>
        <TextField fullWidth variant='outlined' size='small' placeholder='ID name or description' value={params.keyword} className='background-color' onChange={event => onParamsChange({keyword: event.target.value}, 500)} />
      </Grid>
    </Grid>
  )
}
