import AddBtn from 'components/AddBtn'
import { UserSegment } from 'common/enum'
import { isBlankV2 } from 'common/usual'
import { AutoCompleteNew } from 'components/Select'
import { Grid, TextField } from '@material-ui/core'
import ImportFromCSV from 'components/ImportFromCSV'
import React, { useCallback, useEffect, useState } from 'react'
import { upsertSegmentTargetingPromise } from 'api/segmentTargeting'
import DateTimeUTCInput from 'components/DateTimeUTCInput'

export default function ProgramNutritionToolbar (props) {
  const { params, onParamsChange, options } = props

  const [records, setRecords] = useState([])

  const getOptions = useCallback(name => {
    return (options || {})[name] || []
  }, [options])

  useEffect(() => {
    if (isBlankV2(records)) return
    
    const _records = records.map(r => ({ active: true, ...r, gender_segment: r.gender, age_segment: r.age, bmi_segment: r.bmi }))
    upsertSegmentTargetingPromise(_records).then(() => window.location.reload())
  }, [records])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField name='workout_id' label='Workout ID' fullWidth variant='outlined' size='small' className='background-color' value={params.workoutId} onChange={event => onParamsChange({workoutId: event.target.value}, 500)} />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteNew name='gender_segment' label='Gender Segment' disableUnderline={false} value={+params.genderSegment} options={getOptions(UserSegment.Gender)} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({genderSegment: selected && selected.value})}
          />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteNew name='age_segment' label='Age Segment' disableUnderline={false} value={+params.ageSegment} options={getOptions(UserSegment.Age)} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({ageSegment: selected && selected.value})}
          />
        </Grid>

        <Grid item xs={3}>
          <AutoCompleteNew name='bmi_segment' label='BMI Segment' disableUnderline={false} value={+params.bmiSegment} options={getOptions(UserSegment.BMI)} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({bmiSegment: selected && selected.value})}
          />
        </Grid>
        
        <Grid container item xs={1} justifyContent='flex-end'>
          <AddBtn id='add-btn' color='primary' type='submit' variant='contained'
            onClick={e => {
              e.stopPropagation()
              props.history.push({ pathname: '/workout/segment-targeting/create', state: { params } })
            }}
          >
            Add
          </AddBtn>
        </Grid>

        <Grid item xs={3}>
          <DateTimeUTCInput value={params.startTime} className='background-color' placeholder='Start Time' handleChange={startTime => onParamsChange({ startTime })} />
        </Grid>

        <Grid item xs={3}>
          <DateTimeUTCInput value={params.endTime} className='background-color' placeholder='End Time' handleChange={endTime => onParamsChange({ endTime })} />
        </Grid>

        <Grid container item xs={6} justifyContent={'flex-end'}>
          <ImportFromCSV setRecords={setRecords} />
        </Grid>
      </Grid>
    </div>
  )
}
