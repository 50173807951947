import { getApiClient } from './ApiClient'

export const getQualityScoreBoostListPromise = (params) => {
  return getApiClient()
    .get('/workout-resource-quality-score-boosts', { params })
    .then(resp => resp.data)
}

export const getQualityScoreBoostDetailPromise = id => {
  return getApiClient()
    .get(`/workout-resource-quality-score-boosts/${id}`)
    .then(resp => resp.data)
}

export const upsertQualityScoreBoostPromise = (body) => {
  const columns = ['ns_f' ,'sr_f' ,'ns_m' ,'sr_m']
  const records = [].concat(body)
  records.forEach(r => r.active = columns.find(c => +((+(r[c] || 0.0)).toFixed(3)) !== 0) ? r.active : false)

  return getApiClient()
    .post('/workout-resource-quality-score-boosts', { body })
    .then(resp => resp.data)
}
