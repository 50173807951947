import { getApiClient } from './ApiClient'

export const userChallengeListPromise = (page, size, userId) => {
  const params = { page, size }
  if (userId) params.userId = userId

  return getApiClient()
    .get('/user-challenge-list', {
      params: params,
    })
    .then(resp => {
      return Promise.resolve(resp.data)
    })
}

export function challengeListPromise (params) {
  return getApiClient()
    .get('/challenges', { params })
    .then(resp => resp.data)
}

export function challengeDetailPromise (id) {
  return getApiClient()
    .get(`/challenges/${id}`)
    .then(resp => resp.data)
}

export function challengeUpsertPromise (body) {
  return getApiClient()
    .post('/challenges', { body })
    .then(resp => resp.data)
}

export function downloadParticipantsPromise (params) {
  return getApiClient()
    .get('/challenges/participants/download', { params })
    .then(resp => resp.data)
}
