import { getApiClient } from './ApiClient'

export const getWorkoutChapterList = (params) => {
  return getApiClient().get('/workout-chapters', { params }).then(resp => resp.data)
}

export const getWorkoutChapterDetail = (id) => {
  return getApiClient().get(`/workout-chapters/${id}`).then(resp => resp.data)
}

export const upsertWorkoutChapter = (body) => {
  return getApiClient().post('/workout-chapters', { body }).then(resp => resp.data)
}

export const generateWorkoutChapter = (...ids) => {
  return getApiClient().post('/workout-chapters/generate', { body: { ids } }).then(resp => resp.data)
}
