import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { BooleanOptionsWithNull } from 'common/enum'

export default function MerchantToolbar (props) {
  const { params, onParamsChange } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Merchant ID' size='small' value={params.merchantId} className='background-color' onChange={event => onParamsChange({merchantId: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Merchant Name' size='small' value={params.merchantName} className='background-color' onChange={event => onParamsChange({merchantName: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <TextField fullWidth variant='outlined' label='Studio ID' size='small' value={params.studioId} className='background-color' onChange={event => onParamsChange({studioId: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={2}>
          <AutoCompleteNew name='Verified' label='Verified' disableUnderline={false} value={params.isVerified} options={BooleanOptionsWithNull} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({isVerified: selected && selected.value}, 0)}
          />
        </Grid>
      </Grid>
    </div>
  )
}
