import _ from 'lodash'
import RecipeTagTable from './table'
import RecipeTagToolbar from './toolbar'
import { useLocation } from 'react-router-dom'
import { getRecipeTagList } from 'api/RecipeTag'
import React, { useState, useEffect } from 'react'
import { getEnumOptionsPromise } from 'api/Util'

export default function (props) {
  const location = useLocation()
  const [data, setData] = useState([])
  const [filterType, setFilterType] = useState({})

  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    getRecipeTagList(too).then(resp => setData(resp))
  }, [location.search])

  useEffect(() => {
    getEnumOptionsPromise('RecipeTagFilterType')
      .then(resp => setFilterType(_.invert(resp.RecipeTagFilterType)))
  }, [])

  return (
    <div className={'root'}>
      <RecipeTagToolbar {...props} />
      <div className={'content'}>
        <RecipeTagTable {...props} data={data} filterType={filterType} />
      </div>
    </div>
  )
}
