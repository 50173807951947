import _ from 'lodash'
import { useEffectOnce } from 'react-use'
import ContentTable from './table'
import { useLocation } from 'react-router-dom'
import Toolbar from './toolbar'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { getEnumOptionsPromise } from 'api/Util'
import moment from 'moment-timezone'
import { getQualityScoreBoostListPromise } from 'api/QualityScoreBoost'

export default function (props) {
  const ref = useRef()
  const location = useLocation()
  const [data, setData] = useState({})
  const [options, setOptions] = useState([])
  const [params, setParams] = useState({ page: 1, filters: {} })

  useEffect(() => {
    getEnumOptionsPromise('BoostQualityScoreType').then(resp =>
      setOptions(_.map(resp.BoostQualityScoreType, (value, label) => ({ label, value })))
    )
  }, [])

  // start
  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams({ page: 1, ...too })
  })

  const loadData = useCallback(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    if (!moment(too.startTime).isValid() || !moment(too.endTime).isValid()) return

    getQualityScoreBoostListPromise(too).then(resp => setData(resp))
  }, [location.search])

  // load data
  useEffect(() => {
    loadData()
  }, [loadData])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: 1, ...payload }, _.identity)
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])

  const handlePageChange = useCallback((event, page) => {
    handleParamsChange({ page: page + 1 })
  }, [handleParamsChange])

  const handleSortChange = useCallback(payload => {
    handleParamsChange(payload)
  }, [handleParamsChange])
  // end

  return (
    <div className={'root'}>
      <Toolbar {...props} params={params} onParamsChange={handleParamsChange} options={options} loadData={loadData} />
      <div className={'content'}>
        <ContentTable {...props} params={params} data={data} page={params.page} onPageChange={handlePageChange} onSortChange={handleSortChange} options={options} />
      </div>
    </div>
  )
}
