import 'assets/scss/form2.scss'
import { Button, Card, Grid, Switch } from '@material-ui/core'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { AutoCompleteNew } from 'components/Select'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import FormTextField from 'components/FormTextField'
import { upsertRecipeTag } from 'api/RecipeTag'

export default function (props) {
  const { data, history } = props
  const [recipeTag, setRecipeTag] = useState({})
  const columnsNotNull = ['name', 'filter_type', 'sort']

  useLayoutEffect(() => {
    if (data) setRecipeTag(data)
  }, [data])

  const handleChange = useCallback((key, val) => {
    setRecipeTag({ ...recipeTag, [key]: val })
  }, [recipeTag])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    upsertRecipeTag(recipeTag)
      .then(() => history.goBack())
  }, [recipeTag, history])

  const isSaveBtnDisabled = useMemo(() => {
    for (const cname of columnsNotNull)
      if (!recipeTag[cname]) return true
  }, [recipeTag, columnsNotNull])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2>
          {data.id ? 'Edit' : 'New'} Recipe Tag
        </h2>
        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Card>
          <Grid item xs={12} className='form-card-title'>
            <label className='form-card-label'>SETUP</label>
            <Switch id='active' name={'active'} checked={Boolean(recipeTag.active)} onChange={e => handleChange('active', e.target.checked)} />
          </Grid>
          
          <Grid container item xs={12} className='form-card-content'>
            <Grid item xs={6}>
              <FormTextField label='Name' name='name' value={recipeTag.name} onChange={handleChange} />
            </Grid>

            <Grid item xs={6}>
              <div className='form-input-elem'>
                <label className='full-width'>Filter Type</label>
                <AutoCompleteNew inputLeft variant='outlined' enumName='RecipeTagFilterType' disableUnderline={false} value={recipeTag.filter_type} onChange={(event, selected) => handleChange('filter_type', selected && selected.value)} />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className='form-input-elem'>
                <label className='full-width'>Sort</label>
                <NumberAutoComplete name='sequence' from={1} to={100} value={recipeTag.sort} inputLeft={true}
                  handleSelectChange={value => handleChange('sort', value) } variant='outlined' />
              </div>
            </Grid>

          </Grid>
        </Card>
        </Grid>
      </Grid>
    </div>
  </div>
}
