import React, { useMemo, useState, useEffect } from 'react'
import { Card, Button, Grid } from '@material-ui/core'
import FormItem from 'components/FormItem'
import { MultipleAutoCompleteSelect } from 'components/Select'
import { Switch } from '@material-ui/core'
import { optionEnum } from '../../../concerns/workoutOptions'
import AgeAndGenderSwitch from 'components/AgeAndGenderSwitch'
import UploaderForSingleImage from 'components/UploaderForSingleImage'
import _ from 'lodash'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'

export default function WorkoutCategoryUpsertForm (props) {
  const { data, setData, hidden, handleSubmit, handleChange, options } = props
  const [submitStep1BtnStatus, setSubmitStep1BtnStatus] = useState(false)
  const [category, setCategory] = useState({})
  const { resource } = optionEnum

  useEffect(() => {
    setCategory(data)
  }, [data])

  function handleSubmitStep1 () {
    setSubmitStep1BtnStatus(true)
    handleSubmit()
      .then(res => {
        if (!category.id) {
          handleChange({id: res.id}, {value: res.value})
          props.history.push('/workout-categories')
        }
        // handleStepChange('step2')
      }).finally(() => {
        setSubmitStep1BtnStatus(false)
      })
  }

  const commonProps = useMemo(() => ({
    data: category,
    onChange: handleChange
  }), [category, handleChange])

  const assets = [
    { key: 'cover_url', pathInName: '280x380' },
    { key: 'cover_url_big', pathInName: '1005x1260' },
    { key: 'male_cover_url', pathInName: '280x380' },
    { key: 'male_cover_url_big', pathInName: '1005x1260' },
  ]

  return (
    <div className={'root'} hidden={hidden}>
      <Card className={'form-container'}>
        <FormItem label={'Name'} name={'name'} {...commonProps} />
        <FormItem label={'Value'} name={'value'} type={'number'} placeholder={'need a unique value or just leave it blank'} {...commonProps} />
        
        {/* <FormItem label={'Sort'} name={'sort'} type={'number'} {...commonProps} /> */}
        <FormItem label={'Sort'} name={'sort'}>
          <NumberAutoComplete name='sort' from={1} to={100} value={category.sort} handleSelectChange={sort => handleChange({ sort })} />
        </FormItem>

        <FormItem label={'Senior Sort'} name={'senior_sort'}>
          <NumberAutoComplete name='sequence' from={1} to={100} value={category.senior_sort} handleSelectChange={senior_sort => handleChange({ senior_sort })} />
        </FormItem>

        <FormItem name={'heart_rate'} type={'number'} {...commonProps} />
        <FormItem label={'Deeplink'} name={'deeplink'} {...commonProps} />


        {/* <FormItem label={'Cover Url'} name={'cover_url'} {...commonProps} />
        <FormItem label={'Cover Url Big'} name={'cover_url_big'} {...commonProps} />
        <FormItem label={'Male Cover Url'} name={'male_cover_url'} {...commonProps} />
        <FormItem label={'Male Cover Url Big'} name={'male_cover_url_big'} {...commonProps} /> */}

        {/* <FormItem label={'Showing Type'} name={'showing_type'} {...commonProps}>
          <AutoCompleteNew
            options={options[categoryShowingType] || []}
            value={category.showing_type}
            onChange={(event, selected) => {
              handleChange({showing_type: (selected || {value: null}).value})
            }}
          />
        </FormItem> */}

        <FormItem label={'Primary Workout List'} name={'primary_resource_ids'} {...commonProps}>
          <MultipleAutoCompleteSelect
            options={options[resource] || []}
            values={category.primary_resource_ids || []}
            onChange={(event, selected) => {
              handleChange({primary_resource_ids: selected.map(s => s.value)})
            }}
          />
        </FormItem>

        <FormItem label={'Secondary Workout List'} name={'secondary_resource_ids'} {...commonProps}>
          <MultipleAutoCompleteSelect
            options={options[resource] || []}
            values={category.secondary_resource_ids || []}
            onChange={(event, selected) => {
              handleChange({secondary_resource_ids: selected.map(s => s.value)})
            }}
          />
        </FormItem>

        <FormItem name={'show_detail'}>
          <Switch checked={Boolean(category.show_detail)} onChange={event => handleChange({show_detail: event.target.checked})} />
        </FormItem>

        <FormItem name={'show_browse'}>
          <Switch checked={Boolean(category.show_browse)} onChange={event => handleChange({show_browse: event.target.checked})} />
        </FormItem>

        <FormItem name={'show_trainer'}>
          <Switch checked={Boolean(category.show_trainer)} onChange={event => handleChange({show_trainer: event.target.checked})} />
        </FormItem>

        <FormItem label={'Enable in Workout Search'} name={'enable_search'} {...commonProps}>
          <Switch
            checked={Boolean(category.enable_search)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({enable_search: event.target.checked})
            }}
          />
        </FormItem>

        <FormItem label={'Enable Achievement Badges'} name={'enable_achievements'} {...commonProps}>
          <Switch
            checked={Boolean(category.enable_achievements)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({enable_achievements: event.target.checked})
            }}
          />
        </FormItem>

        <AgeAndGenderSwitch data={data} setData={setData} />

        <FormItem label={'Is Workout'} name={'active'} {...commonProps}>
          <Switch
            checked={Boolean(category.is_workout)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({is_workout: event.target.checked})
            }}
          />
        </FormItem>

        <FormItem label={'Active'} name={'active'} {...commonProps}>
          <Switch
            checked={Boolean(category.active)}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(event) => {
              handleChange({active: event.target.checked})
            }}
          />
        </FormItem>

        {/* <S3UploaderWithInput label='Cover URL (280x380)' name='cover_url' prefix='280x380'
          filenames={category.cover_url} fileType={S3PreSignType.WorkoutCover}
          setFilenames={filenames => handleChange({ cover_url: filenames })} />

        <S3UploaderWithInput label='Cover Url Big (1005x1260)' name='cover_url_big' prefix='1005x1260'
          filenames={category.cover_url_big} fileType={S3PreSignType.WorkoutCover}
          setFilenames={filenames => handleChange({ cover_url_big: filenames })} />

        <S3UploaderWithInput label='Male Cover Url (280x380)' name='male_cover_url' prefix='280x380'
          filenames={category.male_cover_url} fileType={S3PreSignType.WorkoutCover}
          setFilenames={filenames => handleChange({ male_cover_url: filenames })} />

        <S3UploaderWithInput label='Male Cover Url Big (1005x1260)' name='male_cover_url_big' prefix='1005x1260'
          filenames={category.male_cover_url_big} fileType={S3PreSignType.WorkoutCover}
          setFilenames={filenames => handleChange({ male_cover_url_big: filenames })} /> */}

        {
          assets.map(({ key, pathInName }) => <div style={{ width: '80%', maxWidth: '1000px', margin: '20px auto 0' }}>
            <div style={{paddingTop: '10px'}}>
              <div className=''>
                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px #E5E5E5 solid', paddingBottom: '10px'}}>
                  <span>
                    {_.startCase(key)} (PNG, JPG) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Image size: <b>{pathInName} px</b>
                  </span>
                </div>
              </div>

              <div style={{marginTop: '15px'}}>
                <UploaderForSingleImage
                  keyName={key}
                  filename={data[key]}
                  pathInFilename={pathInName}
                  fileType='WorkoutCoverBasePath'
                  handleChange={(key, val) => handleChange({ [key]: val })}
                />
              </div>
            </div>
          </div>)
        }
        
        <Button
          className="save-btn"
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmitStep1}
          disabled={submitStep1BtnStatus || !category.name || !category.deeplink}
        >
          SUBMIT
        </Button>
      </Card>
    </div>
  )
}