import React, { useEffect, useState, useCallback } from 'react'
import { generateMagicLinkCode, userDetailPromise } from 'api/User'
import { Card, Avatar, Typography } from '@material-ui/core'
import { getInitials } from 'helpers'
import './index.scss'
import { formatAgeFromDOB } from 'helpers/utils'
import moment from 'moment'
import { SimpleTab } from 'components'
import Context from 'common/context'
import {
  UserDetailAchievement,
  UserDetailChallenge,
  UserDetailPurchase,
  UserDetailWorkout
} from './components'

const UserDetail = props => {
  const { handleNotification } = Context._currentValue
  const {
    match: {
      params: { userId },
    },
  } = props

  const [user, setUser] = useState({})

  const getUserDetail = useCallback(async () => {
    const response = await userDetailPromise(userId)
    if (response) {
      setUser(response)
    }
  }, [userId])

  useEffect(() => {
    getUserDetail()
  }, [getUserDetail, userId])

  return (
    <div className={'root'}>
      <Card className={'user-content'}>
        <Avatar className={'avatar'} src={user.avatar}>
          {getInitials(user.name || '')}
        </Avatar>
        <Typography className="name">{user.name}</Typography>
        <p className="info">
          {user.country_or_state}
          <span className="divide-dot" />
          {[2, 1].includes(user.gender) && !user.genderOther && (
            <>
              {user.gender === 2 ? 'Female' : 'Male'}
              <span className="divide-dot" />
            </>
          )}
          {user.birthday && formatAgeFromDOB(user.birthday)}
        </p>
        <div className="info-item">
          <span className="label">User Name</span>
          <span className="value">{user.user_name}</span>
        </div>
        <div className="info-item">
          <span className="label">Active</span>
          <span className="value">{`${
            user.active === undefined ? '' : user.active
          }`}</span>
        </div>
        <div className="info-item">
          <span className="label">Birthday</span>
          <span className="value">
            {moment(user.birthday).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="info-item">
          <span className="label">bmi</span>
          <span className="value">{user.bmi}</span>
        </div>
        <div className="info-item">
          <span className="label">Country code</span>
          <span className="value">{user.country_code}</span>
        </div>
        <div className="info-item">
          <span className="label">Phone</span>
          <span className="value">{user.phone}</span>
        </div>
        <div className="info-item">
          <span className="label">Email</span>
          <span className="value">{user.email}</span>
        </div>
        <div className="info-item">
          <span className="label">Country or state</span>
          <span className="value">{user.country_or_state}</span>
        </div>
        <div className="info-item">
          <span className="label">city</span>
          <span className="value">{user.city}</span>
        </div>
        <div className="info-item">
          <span className="label">Height</span>
          <span className="value">{user.height + ' ' + user.height_unit}</span>
        </div>
        <div className="info-item">
          <span className="label">Weight</span>
          <span className="value">{user.weight + ' ' + user.weight_unit}</span>
        </div>
        <div className="info-item">
          <span className="label">IP</span>
          <span className="value">{user.ip}</span>
        </div>
        <div className="info-item">
          <span className="label">Timezone</span>
          <span className="value">{user.timezone}</span>
        </div>
        <div className="info-item">
          <span className="label">Create at</span>
          <span className="value">
            {moment(user.created_at).format('DD/MM/YYYY')}
          </span>
        </div>
        <div className="info-item">
          <span className="label">Updated at</span>
          <span className="value">
            {moment(user.updated_at).format('DD/MM/YYYY')}
          </span>
        </div>
      </Card>
      <br />
      <SimpleTab
        tabs={[
          {label: 'Purchase'},
          {label: 'Achievement'},
          {label: 'Challenge'},
          {label: 'Workout'}
        ]}
        panels={[
          {content: <UserDetailPurchase userId={userId} />},
          {content: <UserDetailAchievement userId={userId} />},
          {content: <UserDetailChallenge userId={userId} />},
          {content: <UserDetailWorkout userId={userId} />},
        ]}
        onChange={() => {}}
      />
    </div>
  )
}

export default UserDetail
