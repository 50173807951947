import './index.scss'
import FormItem from 'components/FormItem'
import ActiveSwitch from 'components/ActiveSwitch'
import { AutoCompleteNew } from 'components/Select'
import React, { useState, useEffect, useCallback } from 'react'
import { Card, Button, Switch, Grid, Input } from '@material-ui/core'
import { NumberAutoComplete } from 'components/Select/NumberAutoComplete'
import _ from 'lodash'
import { moveResourceListPromise, moveWorkoutUpsertPromise } from 'api/MoveWorkout'
import { uuidV4 } from 'helpers/utils'
import { Add } from '@material-ui/icons'
import { ParamsForOption } from 'common/enum'

export default function WorkoutCategoryUpsertForm (props) {
  const { mode, data, history } = props
  const [workoutResource, setWorkoutResource] = useState({})
  const [moveWorkout, setMoveWorkout] = useState({})
  const [moveResourceWorkouts, setMoveResourceWorkouts] = useState({})
  const [moveResourceOptions, setMoveResourceOptions] = useState([])

  useEffect(() => {
    moveResourceListPromise({ page: 1, size: 10000, ...ParamsForOption }).then(mr => {
      setMoveResourceOptions(mr.rows.map(f => ({ label: f.name, value: f.id })))
    })
  }, [])

  useEffect(() => {
    setWorkoutResource(data)
    if (!data.move_workout) return
    setMoveWorkout({ ...data.move_workout, workout_resource_id: data.id })

    if (!data.move_workout.move_resource_workouts) return

    const ordered = _.orderBy(data.move_workout.move_resource_workouts, 'sequence', 'asc')

    setMoveResourceWorkouts(ordered.reduce((res, foo) => ({ ...res, [uuidV4()]: foo }), {}))
  }, [data])

  function handleSubmit () {
    moveWorkoutUpsertPromise({ ...moveWorkout, move_resource_workouts: Object.values(moveResourceWorkouts) })
      .then(() => history.goBack())
  }

  const handleMoveResourceWorkoutValueChange = useCallback((uuid, data) => {
    uuid = uuid || uuidV4()

    setMoveResourceWorkouts({
      ...moveResourceWorkouts,
      [uuid]: { ...moveResourceWorkouts[uuid], ...data }
    })
  }, [moveResourceWorkouts])

  return (
    <div className={'root'}>
      <Card className={'form-container'}>
        <FormItem name={'workout_id'} value={workoutResource.id} readonly />
        <FormItem name={'workout_name'} value={workoutResource.name} readonly />

        <FormItem label={'Active'} name={'active'}>
          <ActiveSwitch mode={mode} body={moveWorkout} setBody={setMoveWorkout} isNeedCheck={true} />
        </FormItem>

        <br />
        <br />

        <div style={{ width: '80%', maxWidth: '1000px', margin: '0 auto' }}>
          <Grid container>
            <Grid container xs={12}>
              <Grid item xs={2}>Move Resource</Grid>
              <Grid item xs={2}>Circuit</Grid>
              <Grid item xs={2}>Circuit Repeat Times</Grid>
              <Grid item xs={2}>Sequence</Grid>
              <Grid item xs={2}>Transition Time</Grid>
              <Grid item xs={1}>Active</Grid>
              <Grid item xs={1}>
                <Add className='cursor-pointer' onClick={() => handleMoveResourceWorkoutValueChange()} />
              </Grid>
            </Grid>

            {
              Object.entries(moveResourceWorkouts).map(([uuid, mrw]) =>
                <Grid container item spacing={1} xs={12}>
                  <Grid item xs={2}>
                    <AutoCompleteNew value={mrw.move_resource_id} options={moveResourceOptions} disableUnderline={false} onChange={(event, selected) => handleMoveResourceWorkoutValueChange(uuid, { move_resource_id: selected && selected.value })} />
                  </Grid>
                  <Grid item xs={2}>
                    <Switch name={'circuit'} checked={Boolean(mrw.circuit)} onChange={event => handleMoveResourceWorkoutValueChange(uuid, { circuit: event.target.checked }) } />
                  </Grid>
                  <Grid item xs={2}>
                    <NumberAutoComplete name='circuit_repeat_times' from={1} to={50} value={mrw.circuit_repeat_times} disableUnderline={false} handleSelectChange={circuit_repeat_times => { handleMoveResourceWorkoutValueChange(uuid, { circuit_repeat_times }) }} {...props} />
                  </Grid>
                  <Grid item xs={2}>
                    <NumberAutoComplete name='sequence' from={1} to={100} value={mrw.sequence} disableUnderline={false} handleSelectChange={sequence => { handleMoveResourceWorkoutValueChange(uuid, { sequence }) }} {...props} />
                  </Grid>
                  <Grid item xs={2}>
                    <Input name='transition_time' value={mrw.transition_time} className='input-container' onChange={event => handleMoveResourceWorkoutValueChange(uuid, { transition_time: event.target.value })} />
                  </Grid>
                  <Grid item xs={1}>
                    <Switch name={'active'} checked={Boolean(mrw.active)} onChange={event => handleMoveResourceWorkoutValueChange(uuid, { active: event.target.checked }) } />
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
            )}
          </Grid>
        </div>

        <Button className='save-btn' color='primary' size='large' type='submit' variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </Card>
    </div>
  )
}