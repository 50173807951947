import './index.scss'
import _ from 'lodash'
import { isBlankV2 } from 'common/usual'
import { userListPromise } from 'api/User'
import { useEffectOnce } from 'react-use'
import { useLocation } from 'react-router-dom'
import { UsersToolbar, UsersTable } from './components'
import React, { useState, useEffect, useCallback, useRef } from 'react'

const UserList = props => {
  const ref = useRef()
  const location = useLocation()
  const [users, setUsers] = useState({})
  const [params, setParams] = useState({})

  useEffectOnce(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    setParams(too)
  })

  // load data
  useEffect(() => {
    const foo = new URLSearchParams(location.search)
    const too = Object.fromEntries(foo.entries())

    userListPromise(too).then(resp => setUsers(resp))
  }, [location.search])

  const handleParamsChange = useCallback((payload, delay = 0) => {
    if (ref.current) clearTimeout(ref.current)

    const foo = Object.fromEntries(new URLSearchParams(location.search))
    const too = _.pickBy({ ...foo, page: undefined, ...payload }, foo => !isBlankV2(foo))
    setParams(too)

    ref.current = setTimeout(() => props.history.push(`?${new URLSearchParams(too)}`), delay)
  }, [location.search, props.history])


  const onPageChange = page => {
    handleParamsChange({ page })
  }

  const onSearchChange = (payload, delay = 0) => {
    if (payload === undefined) return
    handleParamsChange(payload, delay)
  }

  return (
    <div className={'root'}>
      <UsersToolbar params={params} onSearchChange={onSearchChange} />
      <div className={'content'}>
        <UsersTable data={users} page={params.page || 1} onPageChange={onPageChange} {...props} />
      </div>
    </div>
  )
}

export default UserList
