import 'assets/scss/form2.scss'
import Row from './components/row'
import { Button, Card, Grid } from '@material-ui/core'
import { generateWorkoutChapter, upsertWorkoutChapter } from 'api/WorkoutChapter'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

export default function (props) {
  const { workoutId, data, history } = props
  const [workoutChapters, setWorkoutChapters] = useState([])

  const defaultRow = { workout_id: workoutId, timestamp: '', move_name: '', active: true }

  useLayoutEffect(() => {
    setWorkoutChapters(data)
  }, [data])

  const cancelClicked = useCallback(() => {
    history.goBack()
  }, [history])

  const saveClicked = useCallback(() => {
    const foo = workoutChapters.filter(wc => wc.id || (wc.timestamp && wc.move_name && wc.active))
    upsertWorkoutChapter(foo)
      .then(() => history.goBack())
  }, [workoutChapters, history])

  const isSaveBtnDisabled = useMemo(() => {
    return !!workoutChapters.find(wc => wc.active && (!wc.timestamp ^ !wc.move_name))
  }, [workoutChapters])

  const handleRowChange = useCallback((index, key, val) => {
    setWorkoutChapters(prev => prev.reduce((res, wc, idx) => {
      if (idx !== index) return res.concat(wc)
      if (key === 'active' && !wc.id && !val) return res
      return res.concat({ ...wc, [key]: val })
    }, []))
  }, [])

  const handleAddRow = useCallback(idx => {
    setWorkoutChapters(prev => {
      prev.splice(idx + 1, 0, defaultRow)
      return [...prev]
    })
  }, [defaultRow])

  useEffect(() => {
    if (workoutChapters.find(wc => wc.active)) return

    setWorkoutChapters(prev => ([...prev, defaultRow]))
  }, [defaultRow, workoutChapters])

  const handleRegenerate = useCallback(() => {
    generateWorkoutChapter(workoutId)
      .then(() => window.location.reload())
  }, [workoutId])

  return <div className='form-page'>
    <div className='form-head-container'>
      <div className='form-head'>
        <h2> Workout Chapter </h2>

        <span>
          <Button variant='contained' color='default' onClick={cancelClicked}>CANCEL</Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant='contained' color='secondary' onClick={handleRegenerate}>REGENERATE</Button>
          &nbsp;&nbsp;&nbsp;
          <Button id='save-btn' variant='contained' color='primary' onClick={saveClicked} disabled={isSaveBtnDisabled}>
            SAVE
          </Button>
        </span>
      </div>
    </div>

    <div className='form-elem-container'>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <SetupCard data={programNutrition} isEdit={isEdit} handleChange={handleChange} /> */}
          <Card>
            <Grid item xs={12} className='form-card-title'>
              <label className='form-card-label'>SETUP</label>
              {/* <Switch id='active' name={'active'} checked={isActive} onChange={e => handleChange('active', e.target.checked)} /> */}
            </Grid>
            
            <Grid container item xs={12} className='form-card-content'>
              <Grid item xs={2}>
                <label className='full-width'>Timestamp</label>
              </Grid>
              <Grid item xs={10}>
                <label className='full-width'>Move Name</label>
              </Grid>

              {
                workoutChapters.map((row, idx) =>
                  row.active && <Row key={idx} idx={idx} data={row} onChange={handleRowChange} onAdd={handleAddRow} />)
              }

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>

  </div>
}
