import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'

const ProgramNutritionTable = props => {
  const { data } = props
  const pageSize = 50
  const [page, setPage] = useState(1)

  const dataInChunk = useMemo(() => _.chunk(data, pageSize), [data])

  const onPageChange = useCallback((event, page) => {
    setPage(page + 1)
  }, [])

  const currentData = useMemo(() => {
    return dataInChunk[page - 1] || []
  }, [dataInChunk, page])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Birthday</TableCell>
                  <TableCell>Height</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Height Unit</TableCell>
                  <TableCell>Weight Unit</TableCell>
                  <TableCell>Sign In From</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Region Name</TableCell>
                  <TableCell>Country Name</TableCell>
                  <TableCell>Purchase Records</TableCell>
                  <TableCell>Comparison Value</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {currentData.map(row => {
                  return <TableRow key={row.id} hover>
                    <TableCell>{row.user_id} </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.gender}</TableCell>
                    <TableCell>{row.birthday}</TableCell>
                    <TableCell>{row.height}</TableCell>
                    <TableCell>{row.weight}</TableCell>
                    <TableCell>{row.height_unit}</TableCell>
                    <TableCell>{row.weight_unit}</TableCell>
                    <TableCell>{row.sign_from}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.region_name}</TableCell>
                    <TableCell>{row.country_name}</TableCell>
                    <TableCell>{row.purchase_records}</TableCell>
                    <TableCell>{row._score}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.length || 0}
          onPageChange={onPageChange}
          page={page - 1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[pageSize]}
        />
      </CardActions>
    </Card>
  )
}

export default ProgramNutritionTable
