import React from 'react'
import AddBtn from 'components/AddBtn'
import { Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'

export default function ProgramNutritionToolbar (props) {
  const { params, onParamsChange, recipeTags } = props

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField fullWidth variant='outlined' label='Name' size='small' value={params.name} className='background-color' onChange={event => onParamsChange({name: event.target.value}, 500)} />
        </Grid>
        <Grid item xs={3}>
          <AutoCompleteNew name='recipe_tag_id' label='Recipe Tag' disableUnderline={false} value={+params.recipeTagId} options={recipeTags} inputLeft variant='outlined'
            className='background-color' onChange={(event, selected) => onParamsChange({recipeTagId: selected && selected.value})}
          />
        </Grid>
        <Grid container item xs={6} justifyContent={'flex-end'}>
          <AddBtn
            id='add-btn'
            color='primary'
            type='submit'
            style={{marginRight: 0}}
            onClick={e => {
              e.stopPropagation()
              props.history.push({
                pathname: '/recipe-tag-options/create',
                state: { params }
              })
            }}
            variant='contained'
          >
            Add
          </AddBtn>
        </Grid>
      </Grid>
    </div>
  )
}
