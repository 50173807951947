import React, { useCallback, useMemo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CardActions,
  TablePagination
} from '@material-ui/core'
import EditBtn from 'components/EditBtn'
import { booleanElement } from 'helpers/utils'
import { FileCopy, TrackChanges, CloudDownload } from '@material-ui/icons'
import { challengeUpsertPromise } from 'api/Challenge'
import { ChallengeFromCustomToOfficialColumnsNeedBeNull } from 'common/enum'
import { checkPermission } from 'helpers/permission'
import { downloadParticipants } from '../util'

const MealTable = props => {
  const { data, page, onPageChange, history } = props

  const handleDuplicate = useCallback((event, challenge) => {
    event.stopPropagation()
    const check = window.confirm('Do you want to duplicate this Challenge')
    if (!check) return

    history.push({
      pathname: '/challenges/create',
      state: { challenge: { ...challenge, join_user_count: undefined, join_user_avatar: undefined } },
    })
  }, [history])

  const handleChangeToOfficial = useCallback((event, challenge) => {
    event.stopPropagation()
    const check = window.confirm('Do you want to change it from a user created challenge to a FitOn challenge')
    if (!check) return

    challengeUpsertPromise({ ...challenge, feature: true, ...ChallengeFromCustomToOfficialColumnsNeedBeNull })
  }, [])

  const isEditPermitted = useMemo(() => checkPermission('105602'), [])

  const handleDownloadParticipants = useCallback((event, challenge) => {
    event.stopPropagation()

    downloadParticipants(challenge)
  }, [])

  return (
    <Card>
      <CardContent className={'table-content'}>
        <PerfectScrollbar>
          <div className={'inner'}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.rows && data.rows.map(challenge => {
                  let platform = JSON.parse(challenge.target_platform || '[]')
                  if (platform.length === 0) platform = [0, 1]

                  return <TableRow className={'table-row cursor-pointer'} key={challenge.id} hover onClick={() => { history.push(`/challenges/${challenge.id}`) }}>
                    <TableCell>{challenge.id}</TableCell>
                    <TableCell>{challenge.name}</TableCell>
                    <TableCell style={{ maxWidth: '200px', lineBreak: 'anywhere' }}>{challenge.description}</TableCell>
                    <TableCell>
                      { booleanElement(challenge.active) }
                    </TableCell>
                    <TableCell>
                      <EditBtn history={props.history} path={`/challenges/${challenge.id}/edit`} />
                      &nbsp;&nbsp;
                      { isEditPermitted && <FileCopy className='cursor-pointer' titleAccess='duplicate' onClick={event => handleDuplicate(event, challenge)} /> }
                      &nbsp;&nbsp;
                      { isEditPermitted && !challenge.feature && <TrackChanges className='cursor-pointer' titleAccess='change it to a FitOn challenge' onClick={event => handleChangeToOfficial(event, challenge)} /> }
                      &nbsp;&nbsp;
                      { challenge.start_date && challenge.end_date && <CloudDownload className='cursor-pointer' titleAccess='download participants' onClick={event => handleDownloadParticipants(event, challenge) } /> }
                    </TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={'flex-right'}>
        <TablePagination
          component="div"
          count={data.count || 0}
          onChangePage={onPageChange}
          page={page-1}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
        />
      </CardActions>
    </Card>
  )
}

export default MealTable