import React from 'react'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

moment.tz.setDefault('Etc/UTC')

export default function DateTimeUTCInput (props) {
  const { label, format, value, handleChange, hideTime, className, placeholder } = props

  const _props = {
    fullWidth: true,
    size: 'small',
    inputVariant: 'outlined',
    format: format || 'YYYY-MM-DD HH:mm:ss',

    value: value || null,
    onChange: handleChange,
    placeholder,
  }

  return (
    <div className={className || 'form-input-elem'}>
      { label && <label className='full-width'>{ label }</label> }
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        {
          hideTime ? <KeyboardDatePicker {..._props} /> : <KeyboardDateTimePicker {..._props} />
        }
      </MuiPickersUtilsProvider>
    </div>
  )
}
